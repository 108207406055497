import * as React from 'react';
import { inject, observer } from 'mobx-react';
import './PermissionsEditPanel.css';
import { IADropDown } from 'intraactive-sdk-ui/dist/DropDown';
import { Store } from '../../../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import PlingService from '../../../../../services/PlingService';
import { IProfile } from '../../../../../interfaces/IProfile';
import { Helper } from '../../../../../Helper';

export interface IProps {
  isAddingUser: boolean;
  profiles?: IProfile[];
  store: Store;
  onClose: () => void;
}

export interface IState {
  searchTerm: string;
  isSearching: boolean;
  users: any[]
  selectedProfile?: string;
  profiles?: { key: string, value: any }[];
}

@inject('store')
@observer
export class PermissionsEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchTerm: '',
      isSearching: false,
      users: []
    }
  }

  public render(): JSX.Element {
    const profileOptions: { key: string, value: any }[] = [];
    if (this.props.profiles && this.props.profiles.length > 0) {
      this.props.profiles.forEach((profile: IProfile) => {
        profileOptions.push(
          {
            key: profile.id as string,
            value: profile.name
          }
        );
      });
    }
    return (
      <IAPanel
        open={this.props.isAddingUser}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        width={440}
        panelId={`permissions-edit-panel`}
        dataAutomationIdPrefix="permissions-edit-panel"
        darkMode={this.props.store.darkMode}
        close={() => {
          this.setState({ isSearching: false, searchTerm: '', users: [] });
          this.props.onClose();
        }}
      >
        {this.props.isAddingUser &&
          <div
            className="IA_permissionsEditPanel"
            style={{
              height: window.innerHeight - 44,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div
              className="IA_permissionsEditPanelContent"
              style={{
                padding: this.props.store.isMobile ? 20 : 40
              }}
            >
              <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.Add}</h3>
              {profileOptions && profileOptions.length > 1 &&
                <IADropDown
                  label={this.localizationService.strings.SelectAProfileToAccess.toUpperCase()}
                  selectedOption={this.state.selectedProfile != undefined ? this.state.selectedProfile : profileOptions[0].key}
                  options={profileOptions}
                  darkMode={this.props.store.darkMode}
                  onChange={(profile) => this.setState({ selectedProfile: profile })}
                  style={{
                    float: "left",
                    width: "100%",
                    marginBottom: 20
                  }}
                  borderRadius={5}
                />
              }
              <div
                className="IA_peoplePicker"
                style={{
                  border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                  backgroundColor: this.props.store.darkMode ? "#414141" : "transparent",
                }}
              >
                <PeoplePicker
                  placeholder={this.localizationService.strings.PeoplePickerPlaceholder}
                  type={PersonType.person}
                  userType={UserType.user}
                  selectionMode="single"
                  style={{
                    borderRadius: 5
                  }}
                  selectionChanged={(e: any) => {
                    const user = e.target.selectedPeople[0];
                    if (this.props.store.isAddingProfileUser) {
                      let profile = this.state.selectedProfile ? this.state.selectedProfile : this.props.profiles![0].id!;
                      PlingService.addProfileUser(this.props.store.context.tid as string, profile, user).then(() => {
                        this.props.onClose();
                        this.props.store.reloadKeyUsers = Helper.getRandomStringKey();
                      })
                    } else {
                      PlingService.addApplicationUser(this.props.store.context.tid as string, user).then(() => {
                        this.props.onClose();
                        this.props.store.reloadKeyUsers = Helper.getRandomStringKey();
                      })
                    }
                  }}
                />
              </div>
            </div>
          </div>
        }
      </IAPanel>
    );
  }
}