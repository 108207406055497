import * as React from 'react';
import './ContactInformation.css';
import { inject, observer } from 'mobx-react';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Store } from '../../../../stores';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField';
import { IAToggle } from 'intraactive-sdk-ui/dist/Toggle';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { AdaptiveCard } from 'adaptivecards-react';
import PlingService from '../../../../services/PlingService';
import { IContactInformation } from '../../../../interfaces/IContactInformation';

export interface IProps {
  store: Store;
}

export interface IState {
}

@inject('store')
@observer
export class ContactInformation extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
    PlingService.getContactInformation(this.props.store.context.tid as string).then((contactInformation: IContactInformation) => {
      this.props.store.contactInformation = contactInformation
    });
  }

  public render(): JSX.Element {
    let welcomeCardPayload = {
      "type": "AdaptiveCard",
      "body": [
        {
          "type": "TextBlock",
          "size": "Medium",
          "weight": "Bolder",
          "text": this.localizationService.strings.SettingsMenuContact
        },
        {
          "type": "TextBlock",
          "text": this.props.store.contactInformation.name,
          "wrap": true,
          "spacing": "Medium",
        },
        {
          "type": "TextBlock",
          "text": this.props.store.contactInformation.email,
          "wrap": true
        },
        {
          "type": "TextBlock",
          "text": this.props.store.contactInformation.company,
          "wrap": true
        }
      ],
      "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
      "version": "1.5"
    }
    return (
      <div
        className={"IA_contactInformation"}
        style={{
        }}
      >
        <IAHeader
          id="WelcomeMessage"
          title={this.localizationService.strings.ContactInformationTitle}
          description={this.localizationService.strings.ContactInformationDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor={"#acd2e1"}
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
              headline: this.localizationService.strings.ContactInformationTitle,
              text: this.localizationService.strings.ContactInformationDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-account-setting.png"
            }
          ]}
        />
        <div
          className={this.props.store.darkMode ? 'IA_templateCardDarkMode' : 'IA_templateCard'}
          onClick={() => {
            this.props.store.showContactInformationPanel = true;
          }}
          style={{
            backgroundColor: this.props.store.darkMode ? "#292929" : "transparent",
            border: this.props.store.darkMode ? "none" : "1px solid #eeeeee"
          }}
        >
          <AdaptiveCard
            payload={welcomeCardPayload}
            style={{
              width: this.props.store.isMobile ? "100%" : 400,
              clear: "both"
            }}
          />
          <IAButton
            buttonColor={this.props.store.highlightColor}
            textColor={"#ffffff"}
            label={this.localizationService.strings.Edit.toUpperCase()}
            darkMode={this.props.store.darkMode}
            onClick={() => { }}
            borderRadius={5}
            style={{
              float: "right",
              marginTop: 10
            }}
          />
        </div>
      </div>
    );
  }
}