import * as React from 'react';
import './Views.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores/Store';
import { Instruction } from './instruction/Instruction';
import { Inbox } from './inbox/Inbox';
import { Templates } from './templates/Templates';
import { Outbox } from './outbox/Outbox';
import { Popup } from '../popup/Popup';
import { AdaptiveCard } from 'adaptivecards-react';
import { SendPanel } from '../sendPanel/SendPanel';
import { SettingsPanel } from './settingsPanel/SettingsPanel';
import { WelcomeMessagePanel } from './settingsPanel/welcomeMessage/welcomeMessagePanel/WelcomeMessagePanel';
import { PermissionsEditPanel } from './settingsPanel/users/permissionsEditPanel/PermissionsEditPanel';
import { ProfilesEditPanel } from './settingsPanel/profiles/profilesEditPanel/ProfilesEditPanel';
import PlingService from '../../services/PlingService';
import { ContactInformationPanel } from './settingsPanel/contactInformation/contactInformationPanel/ContactInformationPanel';
import { OnboardingAddUserPanel } from './settingsPanel/onboarding/onboardingAddUserPanel/OnboardingAddUserPanel';

export interface IProps {
  store: Store;
}

export interface IState {
}

@inject('store')
@observer
export class Views extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      showProfiles: false
    }
  }

  public render(): JSX.Element {
    return (
      <div
        id={"IA_ContentArea"}
        className={"IA_contentArea"}
        style={{
          top: this.props.store.isMobile ? 50 : 0,
          width: this.props.store.isMobile ? "100%" : "calc(100% - 300px)",
          backgroundColor: this.props.store.darkMode ? this.props.store.darkModeBackgroundColor : "#ffffff",
          left: this.props.store.isMobile ? 0 : 300,
          height: this.props.store.isMobile ? "calc(100% - 50px)" : "calc(100% - 0px)",
        }}
      >
        <SettingsPanel
          store={this.props.store}
        />
        <WelcomeMessagePanel
          open={this.props.store.showWelcomeMessagePanel}
          store={this.props.store}
        />
        <ContactInformationPanel
          open={this.props.store.showContactInformationPanel}
          store={this.props.store}
        />
        <PermissionsEditPanel
          store={this.props.store}
          isAddingUser={this.props.store.isAddingApplicationUser || this.props.store.isAddingProfileUser}
          profiles={this.props.store.isAddingProfileUser ? this.props.store.profiles : undefined}
          onClose={() => {
            this.props.store.isAddingApplicationUser = false;
            this.props.store.isAddingProfileUser = false;
          }}
        />
        <OnboardingAddUserPanel
          store={this.props.store}
          isAddingUser={this.props.store.isAddingOnboardingUser}
          onClose={() => {
            this.props.store.isAddingOnboardingUser = false;
          }}
        />
        <ProfilesEditPanel
          open={this.props.store.profileToEdit != undefined}
          store={this.props.store}
          onClose={() => {
            this.props.store.profileToEdit = undefined;
            PlingService.getMyProfilesAdmin(this.props.store.context.tid as string).then((profiles) => {
              this.props.store.setProfiles(profiles);
            });
          }}
        />
        <SendPanel
          id={"sendPlingPanel"}
          profileId={this.props.store.selectedProfile && this.props.store.sendNewPling && this.props.store.selectedProfile.id}
          store={this.props.store}
          onSave={() => {
            this.props.store.sendNewPling = false;
            this.props.store.sendMessagePage = 1;
            this.props.store.template = undefined;
            this.props.store.usersAudience = [];
            this.props.store.groupsAudience = [];
            this.props.store.selectedMenuItem = `outbox_${this.props.store.selectedProfile!.id}`;
          }}
          onClose={() => {
            this.props.store.sendNewPling = false;
            this.props.store.sendMessagePage = 1;
            this.props.store.template = undefined;
            this.props.store.usersAudience = [];
            this.props.store.groupsAudience = [];
          }}
        />
        {this.props.store.isInstalled &&
          <div
            className={"IA_contentAreaWrapper"}
            style={{
              margin: this.props.store.isMobile ? 20 : 40,
              width: this.props.store.isMobile ? "calc(100% - 40px)" : "calc(100% - 80px)",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {this.props.store.selectedMenuItem.includes("inbox") &&
              <Inbox
                store={this.props.store}
              />
            }
            {this.props.store.selectedMenuItem.includes("outbox") &&
              <Outbox
                store={this.props.store}
                selectedProfileId={this.props.store.selectedProfile != undefined ? this.props.store.selectedProfile.id! : ""}
              />
            }
            {this.props.store.selectedMenuItem.includes("templates") &&
              <Templates
                store={this.props.store}
                selectedProfileId={this.props.store.selectedProfile != undefined ? this.props.store.selectedProfile.id! : ""}
              />
            }
            {this.props.store.selectedMenuItem === "instruction" &&
              <Instruction
                store={this.props.store}
              />
            }
          </div>
        }
        {this.props.store.cardToView != undefined &&
          <Popup
            close={() => this.props.store.cardToView = undefined}
            content={
              <AdaptiveCard
                payload={this.props.store.cardToView}
                style={{
                  width: this.props.store.isMobile ? "100%" : 400,
                  clear: "both"
                }}
              />
            }
            darkMode={this.props.store.darkMode}
            isMobile={this.props.store.isMobile}
            style={{
              width: this.props.store.isMobile ? "calc(100% - 40px)" : 440
            }}
          />
        }
      </div>
    );
  }
}