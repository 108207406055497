import * as React from 'react';
import { inject, observer } from 'mobx-react';
import './ProfilesEditPanel.css';
import { Store } from '../../../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import PlingService from '../../../../../services/PlingService';
import { ImagePicker } from '../../../../imagePicker/ImagePicker';

export interface IProps {
  open: boolean;
  store: Store;
  onClose: () => void;
}

export interface IState {
  loading: boolean;
}

@inject('store')
@observer
export class ProfilesEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false
    }
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.open}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        width={440}
        panelId={`profile-edit-panel`}
        dataAutomationIdPrefix="profile-edit-panel"
        darkMode={this.props.store.darkMode}
        navigationsBarContent={
          <IAButton
            buttonColor={this.props.store.highlightColor}
            textColor={"#ffffff"}
            disbaled={this.props.store.profileToEdit?.name == undefined || this.props.store.profileToEdit?.name === ""}
            label={this.props.store.profileToEdit?.id ? this.localizationService.strings.Save.toUpperCase() : this.localizationService.strings.Add.toUpperCase()}
            darkMode={this.props.store.darkMode}
            showSpinner={this.props.store.sending}
            borderRadius={5}
            style={{
              float: "right",
              marginTop: 7
            }}
            onClick={() => {
              this.setState({ loading: true });
              if (this.props.store.profileToEdit?.id) {
                PlingService.updateProfile(this.props.store.context.tid as string, this.props.store.profileToEdit!).then(() => {
                  this.props.store.profileToEdit = undefined;
                  this.setState({ loading: false });
                  this.props.onClose();
                });
              } else {
                PlingService.createProfile(this.props.store.context.tid as string, this.props.store.profileToEdit!).then((id: string) => {
                  this.props.store.createDefaultTemplates(id).then(() => {
                    this.props.store.profileToEdit = undefined;
                    this.setState({ loading: false });
                    this.props.onClose();
                  });
                });
              }
            }}
          />
        }
        close={() => {
          this.props.onClose();
        }}
      >
        {this.props.store.profileToEdit &&
          <div className='IA_profileEditPanel'>
            <div
              className='IA_profileEditPanelContent'
              style={{
                padding: this.props.store.isMobile ? 20 : 40
              }}
            >
              <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>
                {this.props.store.profileToEdit?.id ? this.localizationService.strings.EditProfile : this.localizationService.strings.CreateProfile}
              </h3>
              <IATextField
                label={this.localizationService.strings.Name.toUpperCase()}
                text={this.props.store.profileToEdit!.name!}
                darkMode={this.props.store.darkMode}
                borderRadius={5}
                style={{ marginBottom: '10px' }}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                onChange={(name: string) => {
                  this.props.store.profileToEdit!.name = name;
                }}
              />
              <ImagePicker
                store={this.props.store}
                label={this.localizationService.strings.ProfileImage.toUpperCase()}
                imageUrl={this.props.store.profileToEdit!.logoUrl!}
                onChange={(imageUrl) => {
                  this.props.store.profileToEdit!.logoUrl = imageUrl;
                }}
              />
            </div>
          </div>
        }
      </IAPanel>
    );
  }
}