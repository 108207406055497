import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import './WelcomeMessagePanel.css';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { Store } from '../../../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import PlingService from '../../../../../services/PlingService';

export interface IProps {
  store: Store;
  open: boolean;
}

export interface IState {
  welcomeMessage: string;
}

@inject('store')
@observer
export class WelcomeMessagePanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      welcomeMessage: ""
    }
  }

  componentWillReceiveProps(props: IProps): void {
    if (props.open !== this.props.open && props.open) {
      this.setState({ welcomeMessage: this.props.store.welcomeMessage });
    }
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.open}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={500}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix={`welcome-message-panel`}
        panelId={`welcome-message-panel`}
        isInTeams
        navigationsBarContent={
          <IAButton
            label={this.props.store.templateToEdit?.id ? this.localizationService.strings.Update.toUpperCase() : this.localizationService.strings.Save.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={this.state.welcomeMessage == undefined || this.state.welcomeMessage === ""}
            onClick={() => {
              if (this.state.welcomeMessage != undefined && this.state.welcomeMessage !== "") {
                this.props.store.welcomeMessage = this.state.welcomeMessage;
                PlingService.createWelcomeMessage(this.props.store.context.tid as string, this.props.store.welcomeMessage).then(() => {
                  this.props.store.showWelcomeMessagePanel = false;
                });
              }
            }}
            borderRadius={5}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
            darkMode={this.props.store.darkMode}
          />
        }
        close={() => this.props.store.showWelcomeMessagePanel = false}
      >
        <div
          className='IA_welcomeMessagePanel'
          style={{
            padding: this.props.store.isMobile ? 20 : 40
          }}
        >
          <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.EditWelcomeMessage}</h3>
          <textarea
            className={"IA_textArea"}
            value={this.state.welcomeMessage}
            onChange={(event) => {
              this.setState({ welcomeMessage: event.target.value });
            }}
            style={{
              backgroundColor: this.props.store.darkMode ? this.props.store.darkModeInputFieldBackgroundColor : '#ffffff',
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
              borderRadius: 5,
              border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
              padding: 10,
              boxSizing: "border-box",
              height: 400,
            }}
          />
        </div>
      </IAPanel>
    );
  }
}