import * as React from 'react';
import './Instruction.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores/Store';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { LocalizationService } from '../../../services/LocalizationService';
import { Image, Text, Segment } from '@fluentui/react-northstar'
import { Carousel } from './carousel/Carousel';

export interface IProps {
  store: Store;
}

export interface IState {
}

@inject('store')
@observer
export class Instruction extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    const isMobile = window.innerWidth < 667;
    const infoData = [
      {
        title: this.localizationService.strings.IntroductionSlide1Title,
        image: "https://iapstoragedata.blob.core.windows.net/images/first.png",
        content: this.localizationService.strings.IntroductionSlide1Content
      },
      {
        title: this.localizationService.strings.IntroductionSlide2Title,
        image: "https://iapstoragedata.blob.core.windows.net/images/second.png",
        content: this.localizationService.strings.IntroductionSlide2Content
      },
      {
        title: this.localizationService.strings.IntroductionSlide3Title,
        image: "https://iapstoragedata.blob.core.windows.net/images/Installing.png",
        content: this.localizationService.strings.IntroductionSlide3Content
      },
      {
        title: this.localizationService.strings.IntroductionSlide4Title,
        image: "https://iapstoragedata.blob.core.windows.net/images/security.png",
        content: this.localizationService.strings.IntroductionSlide4Content
      }
    ];
    const items = infoData.map((info) => {
      return (
        {
          content: (
            <div>
              <h3 style={{
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
                paddingBottom: 20
              }}>{info.title}</h3>
              <Segment content={
                <Image
                  src={info.image}
                  style={{ maxHeight: isMobile ? '200' : '250px' }}
                />}
                variables={{
                  boxShadow: '0',
                  backgroundColor: 'transparent'
                }}
              />
              <Segment content={<Text content={info.content}
              />
              }
                variables={{
                  boxShadow: '0',
                  backgroundColor: 'transparent'
                }}
              />
            </div>
          )
        }
      );
    });
    return (
      <div
        className={"IA_Instruction"}
        style={{
        }}
      >
        <IAHeader
          id={"Instructions"}
          title={this.localizationService.strings.InstructionTitle}
          description={this.localizationService.strings.InstructionDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor={"#acd2e1"}
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={undefined}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
              headline: this.localizationService.strings.InstructionTitle,
              text: this.localizationService.strings.InstructionDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-introduction.png"
            }
          ]}
        />
        <div className="IA_carousel">
          <Carousel
            store={this.props.store}
            slides={items}
          />
        </div>
      </div>
    );
  }
}