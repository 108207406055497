import { IGraphUser } from "../interfaces/IGraphUser";
import AuthenticationService from "./AuthenticationService";

export default class GraphService {

  public static async getCurrentUser(tenantId: string): Promise<IGraphUser> {
    const accessToken = await AuthenticationService.getGraphToken(tenantId);
    const requestHeaders = { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` };
    const response = await fetch(`https://graph.microsoft.com/v1.0/me`, { method: "GET", headers: requestHeaders });
    if (!response.ok) throw new Error(" > HttpService > Error in GET request - status: " + response.status);
    const result = await response.json();
    return result;
  }

  public static async getUser(tenantId: string, userId: string): Promise<IGraphUser> {
    const accessToken = await AuthenticationService.getGraphToken(tenantId);
    const requestHeaders = { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` };
    const response = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}`, { method: "GET", headers: requestHeaders });
    if (!response.ok) throw new Error(" > HttpService > Error in GET request - status: " + response.status);
    const result = await response.json();
    return result;
  }
}