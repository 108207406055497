import { TeamsFx } from "@microsoft/teamsfx";
import { action, makeObservable, observable } from "mobx"
import { IMessageBody } from "../interfaces/IMessageBody";
import { ITemplate } from "../interfaces/ITemplate";
import * as ACData from "adaptivecards-templating";
import { IProfile } from "../interfaces/IProfile";
import { IMessage } from "../interfaces/IMessage";
import { ITrackingEntity, ITrackingMessage } from "../interfaces/ITracking";
import moment from 'moment';
import { IContactInformation } from "../interfaces/IContactInformation";
import { LocalizationService } from "../services/LocalizationService";
import PlingService from "../services/PlingService";
import { ITemplateParameter } from "../interfaces/ITemplateParameter";
import { DynamicObject } from "../interfaces/DynamicObject";

export class Store {
  @observable teamsFx: TeamsFx | undefined;
  @observable themeString!: string;

  @observable context!: microsoftTeams.Context;
  @observable title!: string;
  @observable template?: ITemplate;
  @observable content!: string;
  @observable templates?: ITemplate[];
  @observable card: any;
  @observable messageBody?: IMessageBody;
  @observable audience: any[];
  @observable usersAudience: any[];
  @observable groupsAudience: any[];
  @observable selectedMenuItem;
  @observable isInstalled: boolean;
  @observable profiles: IProfile[];
  @observable selectedProfile?: IProfile;
  @observable openNavigation: boolean;
  @observable messagePriority!: string;
  @observable imageFile!: string;
  @observable priorityString!: string;
  @observable hasBotAdmin: boolean = false;
  @observable user: any;
  @observable public selectedMessage?: IMessage;
  @observable public darkMode: boolean = false;
  @observable public isMobile: boolean = false;
  @observable public showMobileMenu: boolean = false;
  @observable public highlightColor: string = "#45b4dd";
  @observable public isBotAdmin: boolean = false;
  @observable public reloadKeyUsers!: string;
  @observable public reloadKeyOnboarding!: string;

  @observable public sendNewPling: boolean = false;
  @observable public sentPlings?: IMessage[];
  @observable public totalSentMessages: number;
  @observable public receivedPlings?: IMessage[];
  @observable public totalreceivedMessages: number;

  // SEND MESSAGE
  @observable public sendMessagePage: number = 1;
  @observable public sending: boolean = false;

  // DARK MODE COLORS
  @observable public darkModeBackgroundColor: string = "#1f1f1f";
  @observable public darkModeLabelColor: string = "#A8A8A8";
  @observable public darkModeCardBackgroundColor: string = "#323232";
  @observable public darkModeInputFieldBackgroundColor: string = "#414141";

  // TRACKING
  @observable public trackingMessages?: ITrackingMessage[];
  @observable public trackedMessage?: ITrackingMessage;
  @observable public totalTrackingMessages: number;
  @observable public showTrackingDetails: boolean = false;

  // TEMPLATE
  @observable public templateToEdit?: ITemplate;

  @observable public cardToView: any;

  constructor() {
    makeObservable(this);
    this.audience = [];
    this.usersAudience = [];
    this.groupsAudience = [];
    this.templates = [];
    this.selectedMenuItem = 'inbox';
    this.isInstalled = false;
    this.profiles = [];
    this.openNavigation = true;
    this.messagePriority = 'emphasis';
    this.totalSentMessages = 0;
    this.totalreceivedMessages = 0;
    this.totalTrackingMessages = 0;
  }

  @action public setTrackedMessageEntities(entities: ITrackingEntity[]) {
    if (this.trackedMessage) {
      if (!this.trackedMessage.entities) {
        this.trackedMessage.entities = [];
      }
      this.trackedMessage.entities.push(...entities);
    }
  }

  @action public setContext(context: microsoftTeams.Context) {
    this.context = context;
  }

  @action public setProfiles(profiles: IProfile[]) {
    if (profiles && profiles.length > 0) {
      profiles.forEach((profile: IProfile) => {
        profile.logoUrl = profile.logoUrl ? profile.logoUrl : "https://iapstoragedata.blob.core.windows.net/images/IA-Pling.png"
      });
      this.profiles = profiles;
      this.selectedProfile = profiles[0];
    }
  }

  @action public setTemplates(templates: ITemplate[]) {
    this.templates = templates;
  }

  @action public getType(property: any): string {
    let type: any = typeof property;
    if (type === "string" && moment(property).isValid()) {
      type = "date";
    }
    return type;
  }

  @action public getProperties(sampleDataAsString: string): any[] {
    const properties: any[] = [];
    if (sampleDataAsString && sampleDataAsString !== "" && sampleDataAsString.length > 0) {
      try {
        const sampleData = JSON.parse(sampleDataAsString);
        Object.keys(sampleData).forEach((key: string) => {
          properties.push(
            {
              name: key,
              type: this.getType(sampleData[key]),
              placeholder: typeof sampleData[key] === "object" ? JSON.stringify(sampleData[key]) : sampleData[key]
            }
          )
        });
      } catch (error) {
        console.error(error);
      }
    }
    return properties;
  }

  @action public addSampleDataToCardPayload(cardPayload: any, sampleData: any): any {
    let template = new ACData.Template(cardPayload);
    let card = template.expand({
      $root: sampleData
    });
    return card;
  }

  @action public generateSampleDataFromProperties(properties: ITemplateParameter[]): object {
    let sampleData: DynamicObject = {};
    properties.forEach((property: ITemplateParameter) => {
      if (property.type == "object") {
        sampleData[property.name] = property.value ? this.parseStringSafely(property.value) : this.parseStringSafely(property.placeholder);
      } else {
        sampleData[property.name] = property.value ? property.value : property.placeholder;
      }
    });
    return sampleData;
  }

  @action public copySampleData(properties: ITemplateParameter[]): object {
    let sampleData: DynamicObject = {};
    if (properties && properties.length > 0) {
      properties.forEach((property: ITemplateParameter) => {
        if (property.type == "object") {
          sampleData[property.name] = this.parseStringSafely(property.placeholder);
        } else {
          sampleData[property.name] = property.placeholder;
        }
      });
    }
    return sampleData;
  }

  private parseStringSafely(value: string): object {
    if (value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        console.warn(error);
        return {};
      }
    } else {
      return {};
    }
  }

  @action public async createDefaultTemplates(profileId: string): Promise<void> {
    const localizationService: LocalizationService = new LocalizationService();
    const defaultTemplate1: ITemplate = {
      name: localizationService.strings.DefaultTemplate1Name,
      icon: "Card",
      description: "Description",
      template: {
        "type": "AdaptiveCard",
        "body": [
          {
            "type": "TextBlock",
            "size": "Medium",
            "weight": "Bolder",
            "text": "${title}"
          },
          {
            "type": "TextBlock",
            "text": "${description}",
            "wrap": true
          }
        ],
        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
        "version": "1.5"
      },
      parameters: [
        {
          "name": "title",
          "type": "string",
          "placeholder": localizationService.strings.DefaultTemplate1Title
        },
        {
          "name": "description",
          "type": "string",
          "placeholder": localizationService.strings.DefaultTemplate1Description
        }
      ]
    };
    const defaultTemplate2: ITemplate = {
      name: localizationService.strings.DefaultTemplate2Name,
      icon: "Card",
      description: "Description",
      template: {
        "type": "AdaptiveCard",
        "body": [
          {
            "type": "Image",
            "url": "${imageUrl}",
            "size": "Stretch"
          },
          {
            "type": "TextBlock",
            "size": "Medium",
            "weight": "Bolder",
            "text": "${title}"
          },
          {
            "type": "TextBlock",
            "text": "${description}",
            "wrap": true
          }
        ],
        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
        "version": "1.5"
      },
      parameters: [
        {
          "name": "title",
          "type": "string",
          "placeholder": localizationService.strings.DefaultTemplate2Title
        },
        {
          "name": "description",
          "type": "string",
          "placeholder": localizationService.strings.DefaultTemplate2Description
        },
        {
          "name": "imageUrl",
          "type": "image",
          "placeholder": localizationService.strings.DefaultTemplate2ImageUrl
        }
      ]
    };
    await PlingService.createAdaptiveCardsTemplate(this.context.tid as string, profileId, defaultTemplate1).then(async () => {
      return await PlingService.createAdaptiveCardsTemplate(this.context.tid as string, profileId, defaultTemplate2);
    });
  }

  // SETTINGS

  @observable public showSettingsPanel: boolean = false;
  @observable public showMobileSettingsMenu: boolean = false;
  @observable public selectedSettingsMenuItem: string = "profiles";
  @observable public showWelcomeMessagePanel: boolean = false;
  @observable public welcomeMessage: string = "";
  @observable public contactInformation: IContactInformation = {};
  @observable public isAddingApplicationUser: boolean = false;
  @observable public isAddingProfileUser: boolean = false;
  @observable public profileToEdit?: IProfile;
  @observable public showContactInformationPanel: boolean = false;
  @observable public showOnboardingAddUserPanel: boolean = false;
  @observable public isAddingOnboardingUser: boolean = false;
}