import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import './ContactInformationPanel.css';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { Store } from '../../../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField';
import PlingService from '../../../../../services/PlingService';

export interface IProps {
  store: Store;
  open: boolean;
}

export interface IState {
  name?: string;
  mail?: string;
  company?: string;
}

@inject('store')
@observer
export class ContactInformationPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  componentWillReceiveProps(props: IProps): void {
    if (props.open !== this.props.open && props.open) {
      this.setState({
        name: this.props.store.contactInformation.name,
        mail: this.props.store.contactInformation.email,
        company: this.props.store.contactInformation.company
      });
    }
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.open}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={500}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix={`welcome-message-panel`}
        panelId={`welcome-message-panel`}
        isInTeams
        navigationsBarContent={
          <IAButton
            label={this.localizationService.strings.Save.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={
              this.state.name == undefined ||
              this.state.mail == undefined ||
              this.state.company == undefined ||
              this.state.name == "" ||
              this.state.mail == "" ||
              this.state.company == ""
            }
            onClick={() => {
              this.props.store.contactInformation = {
                name: this.state.name,
                email: this.state.mail,
                company: this.state.company
              }
              PlingService.createContactInformation(this.props.store.context.tid as string, this.props.store.contactInformation).then(() => {
                this.props.store.showContactInformationPanel = false;
              });
            }}
            borderRadius={5}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
            darkMode={this.props.store.darkMode}
          />
        }
        close={() => this.props.store.showContactInformationPanel = false}
      >
        <div
          className='IA_welcomeMessagePanel'
          style={{
            padding: this.props.store.isMobile ? 20 : 40
          }}
        >
          <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.EditContactInformation}</h3>
          <IATextField
            label={this.localizationService.strings.ContactInformationName.toUpperCase()}
            placeholder={this.localizationService.strings.ContactInformationName}
            text={this.state.name!}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            style={{ marginBottom: '10px' }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            onChange={(name: string) => {
              this.setState({ name });
            }}
          />
          <IATextField
            label={this.localizationService.strings.ContactInformationMail.toUpperCase()}
            placeholder={this.localizationService.strings.ContactInformationMail}
            text={this.state.mail!}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            style={{ marginBottom: '10px' }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            onChange={(mail: string) => {
              this.setState({ mail });
            }}
          />
          <IATextField
            label={this.localizationService.strings.ContactInformationCompany.toUpperCase()}
            placeholder={this.localizationService.strings.ContactInformationCompany}
            text={this.state.company!}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            style={{ marginBottom: '10px' }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            onChange={(company: string) => {
              this.setState({ company });
            }}
          />
        </div>
      </IAPanel>
    );
  }
}