import * as React from 'react';
import './TrackingDataPanel.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../../stores/Store';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import { IAIcon } from 'intraactive-sdk-ui/dist/Icon';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { IAPersonaSmall } from 'intraactive-sdk-ui/dist/PersonaSmall';
import moment from 'moment';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField';
import PlingService from '../../../../services/PlingService';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';

export interface IProps {
  store: Store;
  id: string;
  onClose: () => void;
}

export interface IState {
  isLoading: boolean;
  skip: number;
  take: number;
  searchUser: string;
  overviewTrackingState: string;
}

@inject('store')
@observer
export class TrackingDataPanel extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();
  private debounceInputTimerId!: ReturnType<typeof setTimeout>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      skip: 0,
      take: 10,
      searchUser: '',
      overviewTrackingState: 'Any'
    }
  }

  public componentDidUpdate(): void {
    if (this.props.store.trackedMessage) {
      this.props.store.trackedMessage.totalEntities = (this.props.store.trackedMessage?.overview.successCount as number) + (this.props.store.trackedMessage?.overview.pendingCount as number) + (this.props.store.trackedMessage?.overview.failCount as number);
    }
  }

  private showNextPageButton(skip: number, take: number, totalCount: number): boolean {
    return (skip + take) - totalCount < 0;
  }

  private getTrackingData() {
    this.setState({ isLoading: true }, () => {
      PlingService.getTrackingMessageData(this.props.store.context.tid as string, this.props.store.selectedProfile?.id as string, this.props.store.trackedMessage?.id as string,
        this.state.skip, this.state.take, this.state.searchUser, this.state.overviewTrackingState).then(result => {
          if (this.state.skip === 0 && this.props.store.trackedMessage) {
            this.props.store.trackedMessage.entities = [];
          }
          this.props.store.setTrackedMessageEntities(result.entries);
          this.setState({ isLoading: false });
        });
    });
  }

  private getTotalEntities(): number {
    switch (this.state.overviewTrackingState) {
      case 'Success':
        return this.props.store.trackedMessage?.overview.successCount as number;
      case 'Pending':
        return this.props.store.trackedMessage?.overview.pendingCount as number;
      case 'Fail':
        return this.props.store.trackedMessage?.overview.failCount as number;
      default:
        return this.props.store.trackedMessage?.totalEntities as number;
    }
  }

  private getTrackingStateColor(trackingState: string) {
    switch (trackingState) {
      case "SentToUser":
        return '#92c352'
      case "FailedToSend":
      case "FailedNotInstalled":
      case "UserNotWhitelisted":
      case "FailedToSendReachedMaximumRetries":
        return 'red';
      case "NoState":
      case "Working":
      case "WorkingInstalling":
      case "UserUnsubscribed":
      case "HasBeenReceived":
        return 'gray';
      default:
        return "gray";
    }
  }

  private getTrackingStateLocalized(trackingState: string) {
    switch (trackingState) {
      case "SentToUser":
        return this.localizationService.strings.SentToUser;
      case "HasBeenReceived":
        return this.localizationService.strings.HasBeenReceived;
      case "FailedToSend":
        return this.localizationService.strings.FailedToSend;
      case "FailedNotInstalled":
        return this.localizationService.strings.FailedNotInstalled;
      case "UserNotWhitelisted":
        return this.localizationService.strings.UserNotWhitelisted;
      case "UserUnsubscribed":
        return this.localizationService.strings.UserUnsubscribed;
      case "NoState":
        return this.localizationService.strings.NoState;
      case "Working":
        return this.localizationService.strings.Working;
      case "WorkingInstalling":
        return this.localizationService.strings.WorkingInstalling;
      case "FailedToSendReachedMaximumRetries":
        return this.localizationService.strings.FailedToSendReachedMaximumRetries;
    }
  }

  public render(): JSX.Element {
    let userList: any[] = [];
    if (!this.state.isLoading && !this.props.store.trackedMessage?.entities && this.props.store.trackedMessage?.id) {
      this.getTrackingData()
    }
    if (this.props.store.trackedMessage) {
      this.props.store.trackedMessage.entities?.forEach(user => {
        let persona = (
          <IAPersonaSmall
            name={user.displayName}
            backgroundColor={this.props.store.highlightColor}
            profileImage={user.userPrincipalName}
            metadata={this.getTrackingStateLocalized(user.trackingState)}
            styleMetadata={{ color: this.getTrackingStateColor(user.trackingState) }}
          />
        );
        let sentDate = moment(user.trackedDate).format('DD-MM-YYYY HH:mm');

        userList.push(
          <div style={{ float: 'left', width: '100%' }}>
            <div style={{ float: 'left', width: '50%' }}>{persona}</div>
            <div style={{ float: 'right', margin: '15px' }}>{sentDate}</div>
          </div>
        );
      });
    }
    const panel = (
      <div>
        <div className='HeaderInfo'>
          <div style={{ fontWeight: 'bold', marginTop: '20px', fontSize: '16px' }}>{this.props.store.trackedMessage?.title}</div>
          <div style={{ fontSize: '12px' }} >{this.props.store.trackedMessage?.created ? moment(this.props.store.trackedMessage?.created).format('[Sent] DD-MM-YYYY HH:mm') : ''}</div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {/* All */}
            <div
              className='IA_label'
              style={{
                opacity: this.state.overviewTrackingState === 'Any' ? 1 : 0.5,
                cursor: "pointer"
              }}
              onClick={() => {
                this.setState({ overviewTrackingState: 'Any', skip: 0 }, this.getTrackingData);
              }}
            >
              <div
                className='IA_labelText'
                style={{
                  backgroundColor: this.props.store.darkMode ? "#414141" : "#e6e6e2",
                  marginLeft: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                  maxWidth: "unset",
                  marginRight: 10
                }}
              >
                {`${this.localizationService.strings.All.toUpperCase()} (${this.props.store.trackedMessage?.totalEntities})`}
              </div>
            </div>
            {/* Success */}
            <div
              className='IA_label'
              style={{
                opacity: this.state.overviewTrackingState === 'Success' ? 1 : 0.5,
                cursor: "pointer"
              }}
              onClick={() => {
                this.setState({ overviewTrackingState: 'Success', skip: 0 }, this.getTrackingData);
              }}
            >
              <img
                className='IA_labelImage'
                src={"https://intraactivestorage.blob.core.windows.net/cdn/pling/success.png"}
                style={{
                  backgroundColor: "#92c352"
                }}
              />
              <div
                className='IA_labelText'
                style={{
                  backgroundColor: this.props.store.darkMode ? "#414141" : "#e6e6e2",
                  marginLeft: -30,
                  paddingLeft: 35,
                  paddingRight: 20
                }}
              >
                {`${this.props.store.trackedMessage?.overview.successCount}`}
              </div>
            </div>
            {/* Pending */}
            <div
              className='IA_label'
              style={{
                opacity: this.state.overviewTrackingState === 'Pending' ? 1 : 0.5,
                cursor: "pointer"
              }}
              onClick={() => {
                this.setState({ overviewTrackingState: 'Pending', skip: 0 }, this.getTrackingData);
              }}
            >
              <img
                className='IA_labelImage'
                src={"https://intraactivestorage.blob.core.windows.net/cdn/pling/pending.png"}
                style={{
                  backgroundColor: "gray"
                }}
              />
              <div
                className='IA_labelText'
                style={{
                  backgroundColor: this.props.store.darkMode ? "#414141" : "#e6e6e2",
                  marginLeft: -30,
                  paddingLeft: 35,
                  paddingRight: 20
                }}
              >
                {`${this.props.store.trackedMessage?.overview.pendingCount}`}
              </div>
            </div>
            {/* Fail */}
            <div
              className='IA_label'
              style={{
                opacity: this.state.overviewTrackingState === 'Fail' ? 1 : 0.5,
                cursor: "pointer"
              }}
              onClick={() => {
                this.setState({ overviewTrackingState: 'Fail', skip: 0 }, this.getTrackingData);
              }}
            >
              <img
                className='IA_labelImage'
                src={"https://intraactivestorage.blob.core.windows.net/cdn/pling/failed.png"}
                style={{
                  backgroundColor: "red"
                }}
              />
              <div
                className='IA_labelText'
                style={{
                  backgroundColor: this.props.store.darkMode ? "#414141" : "#e6e6e2",
                  marginLeft: -30,
                  paddingLeft: 35,
                  paddingRight: 20
                }}
              >
                {`${this.props.store.trackedMessage?.overview.failCount}`}
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: '10px' }}>
            <IATextField
              required
              placeholder={this.localizationService.strings.FindPerson}
              text={this.state.searchUser}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              style={{ marginBottom: '10px' }}
              onChange={(searchUser: string) => {
                this.setState({ searchUser: searchUser, isLoading: true }, () => {
                  clearTimeout(this.debounceInputTimerId);
                  this.debounceInputTimerId = setTimeout(() => {
                    if (!this.props.store.trackedMessage?.entities && this.props.store.trackedMessage?.id) {
                      this.getTrackingData()
                    }
                  }, 500);
                })
              }}
            />
          </div>
        </div>
        <div className='userList'>
          {this.state.isLoading &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                marginLeft: "calc(50% - 20px)"
              }}
            />}
          {!this.state.isLoading && userList}
          {this.showNextPageButton(this.state.skip, this.state.take, this.getTotalEntities())
            && !this.state.isLoading &&
            <div className={"IA_paginationButtonContainer"}>
              <IAButton
                onClick={() => this.setState({ skip: this.state.skip + this.state.take, isLoading: true }, this.getTrackingData)}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                textColor="#ffffff"
                borderRadius={5}
                label={this.localizationService.strings.LoadMore.toUpperCase()}
              />
            </div>
          }
        </div>
      </div>
    );

    return (
      <IAPanel
        open={this.props.store.showTrackingDetails}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={540}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix={`view-${this.props.id}`}
        panelId={`view-${this.props.id}`}
        isInTeams
        navigationsBarContent={undefined
        }
        close={() => {
          this.props.store.showTrackingDetails = false;
          this.props.store.trackedMessage = undefined;
        }}
      >
        <div className='IA_Panel'>
          <div 
          className='IA_content'
          style={{
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }}
          >
            {panel}
          </div>
        </div>
      </IAPanel>
    );
  }

}
