import HttpService from "./HttpService";
import { IMessageBody } from "../interfaces/IMessageBody";
import { IIsInstalledResponse } from "../interfaces/IIsInstalledResponse";
import { ITemplate } from "../interfaces/ITemplate";
import AuthenticationService from "./AuthenticationService";
import { IProfile } from "../interfaces/IProfile";
import { localStoragePrefix } from "../constants";
import { IAddBotAdminBody } from "../interfaces/IAddBotAdminBody";
import { ITrackingMessage } from "../interfaces/ITracking";
import { IContactInformation } from "../interfaces/IContactInformation";
import { ISaveImageResponse } from "../interfaces/ISaveImageResponse";
import { ISaveImageRequest } from "../interfaces/ISaveImageRequest";
import { IOnboardingUser } from "../interfaces/IOnboardingUser";
import { IOnboardingStatus } from "../interfaces/IOnboardingStatus";

export default class PlingService {

  public static async isInstalled(tenantId: string): Promise<boolean> {
    const localStorageKey = `${localStoragePrefix}.isInstalled`;
    // Check local storage value
    const isInstalled = localStorage.getItem(localStorageKey);
    if (isInstalled === "true") {
      return true;
    }
    // Check Pling API app consent
    const isPlingApiConsented = (await HttpService.httpGet<IIsInstalledResponse>(`${tenantId}/installation/status`)).isInstalled;
    if (isPlingApiConsented === false) {
      return false;
    }
    // Check Pling app consent
    await AuthenticationService.getGraphToken(tenantId);
    // Save local storage value for next time
    localStorage.setItem(localStorageKey, "true");
    return true;
  }

  public static async isBotAdmin(tenantId: string): Promise<boolean> {
    let result = await HttpService.httpGet<any>(`${tenantId}/bot/admins/me`);
    return result.value;
  }

  public static async verifyAddBotAdmin(tenantId: string, body: IAddBotAdminBody): Promise<any> {
    return await HttpService.httpPost<any>(`${tenantId}/verify/bot/admins`, body);
  }

  public static async verifyHasBotAdmin(tenantId: string): Promise<any> {
    return await HttpService.httpGet<any>(`${tenantId}/verify/bot/admins`);
  }

  public static async VerifyInstallationComplete(tenantId: string): Promise<boolean> {
    const localStorageKey = `${localStoragePrefix}.hasDefaultProfile`;
    // Check local storage value
    const hasDefaultProfile = localStorage.getItem(localStorageKey);
    if (hasDefaultProfile) {
      return true;
    }
    try {
      await HttpService.httpPost<any>(`${tenantId}/verify/installation`);
    } catch (e) {
      console.log("Failed to create default Profile \n" + e);
      return false;
    }
    localStorage.setItem(localStorageKey, "true");
    return true;
  }

  public static async getMyProfilesAdmin(tenantId: string): Promise<IProfile[]> {
    return await HttpService.httpGet<IProfile[]>(`${tenantId}/profiles/admins/me`);
  }

  // Profile

  public static async getProfiles(tenantId: string): Promise<IProfile[]> {
    return await HttpService.httpGet<IProfile[]>(`${tenantId}/profiles`);
  }

  public static async createProfile(tenantId: string, profile: IProfile): Promise<string> {
    let result = await HttpService.httpPost<any>(`${tenantId}/profiles/`, profile);
    return result.id;
  }

  public static async updateProfile(tenantId: string, profile: IProfile): Promise<void> {
    return await HttpService.httpPut<void>(`${tenantId}/profiles/${profile.id}`, profile);
  }

  public static async deleteProfile(tenantId: string, profileId: string): Promise<void> {
    return await HttpService.httpDelete<void>(`${tenantId}/profiles/${profileId}`);
  }

  // Users

  public static async getApplicationUsers(tenantId: string): Promise<string[]> {
    let result = await HttpService.httpGet<any>(`${tenantId}/bot/admins`);
    return result && result.userIds;
  }

  public static async getProfileUsers(tenantId: string, profileId: string): Promise<string[]> {
    let result = await HttpService.httpGet<any>(`${tenantId}/profiles/${profileId}/admins`);
    return result && result.userIds;
  }

  public static async addProfileUser(tenantId: string, profileId: string, user: any): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/profiles/${profileId}/admins/${user.id}`, {
      userPrincipalName: user.userPrincipalName,
      displayName: user.displayName
    });
  }

  public static async addApplicationUser(tenantId: string, user: any): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/bot/admins/${user.id}`, {
      userPrincipalName: user.userPrincipalName,
    });
  }

  public static async deleteProfileUser(tenantId: string, profileId: string, userId: string): Promise<void> {
    return await HttpService.httpDelete<void>(`${tenantId}/profiles/${profileId}/admins/${userId}`);
  }

  public static async deleteApplicationUser(tenantId: string, userId: string): Promise<void> {
    return await HttpService.httpDelete<void>(`${tenantId}/bot/admins/${userId}`);
  }

  // Templates

  public static async getAdaptiveCardsTemplates(tenantId: string, profileId: string): Promise<ITemplate[]> {
    return await HttpService.httpGet<ITemplate[]>(`${tenantId}/profiles/${profileId}/templates`);
  }

  public static async createAdaptiveCardsTemplate(tenantId: string, profileId: string, template: ITemplate): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/profiles/${profileId}/templates`, template);
  }

  public static async updateAdaptiveCardsTemplate(tenantId: string, profileId: string, template: ITemplate): Promise<void> {
    return await HttpService.httpPut<void>(`${tenantId}/profiles/${profileId}/templates/${template.id}`, template);
  }

  public static async deleteAdaptiveCardsTemplate(tenantId: string, profileId: string, templateId: string): Promise<void> {
    return await HttpService.httpDelete<void>(`${tenantId}/profiles/${profileId}/templates/${templateId}`);
  }

  // Inbox

  public static async getMyReceivedMessages(tenantId: string, skip?: number, take?: number): Promise<any> {
    return await HttpService.httpGet<any>(`${tenantId}/messages/received?skip=${skip}&take=${take}`);
  }

  // Outbox

  public static async getTrackingMessageOverview(tenantId: string, profileId: string, messageId: string) {
    return await HttpService.httpGet<any>(`${tenantId}/profiles/${profileId}/messages/${messageId}/tracking/overview`);
  }

  public static async getTrackingMessageData(tenantId: string, profileId: string, messageId: string, skip?: number, take?: number, search?: string, overviewTrackingState?: string) {
    return await HttpService.httpGet<any>(`${tenantId}/profiles/${profileId}/messages/${messageId}/tracking?from=${skip}&count=${take}&searchTerm=${search}&overviewTrackingState=${overviewTrackingState}`);
  }

  public static async getTrackingMessages(tenantId: string, profileId: string, skip?: number, take?: number) {
    const result = await HttpService.httpGet<any>(`${tenantId}/profiles/${profileId}/messages?skip=${skip}&take=${take}`);
    let promises: any[] = [];
    result.value.forEach(async (message: ITrackingMessage) => {
      promises.push(this.getTrackingMessageOverview(tenantId, profileId, message.id));
    });
    const promisesResult = await Promise.all(promises);
    for (let i = 0; i < result.value.length; i++) {
      result.value[i].overview = promisesResult[i];
    }
    return result;
  }

  // Send notifications

  public static async sendAdaptiveCard(tenantId: string, profileId: string, messageBody: IMessageBody): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/profiles/${profileId}/messages`, messageBody);
  }

  public static async saveImage(tenantId: string, profileId: string, saveImageRequest: ISaveImageRequest): Promise<ISaveImageResponse> {
    return await HttpService.httpPost<ISaveImageResponse>(`${tenantId}/profiles/${profileId}/images`, saveImageRequest);
  };

  // Welcome message

  public static async createWelcomeMessage(tenantId: string, welcomeMessage: string): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/welcome`, { content: welcomeMessage });
  }

  public static async getWelcomeMessage(tenantId: string): Promise<string> {
    let result = await HttpService.httpGet<any>(`${tenantId}/welcome`);
    return result.content;
  }

  // Contact information

  public static async createContactInformation(tenantId: string, contactInformation: IContactInformation): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/contact`, contactInformation);
  }

  public static async getContactInformation(tenantId: string): Promise<IContactInformation> {
    return await HttpService.httpGet<IContactInformation>(`${tenantId}/contact`);
  }

  // Onboarding

  public static async getOnboardingStatus(tenantId: string): Promise<IOnboardingStatus> {
    return await HttpService.httpGet<IOnboardingStatus>(`${tenantId}/whitelist`);
  }

  public static async updateOnboardingStatus(tenantId: string, onboardingStatus: IOnboardingStatus): Promise<void> {
    return await HttpService.httpPut<void>(`${tenantId}/whitelist`, onboardingStatus);
  }

  public static async getOnboardingUsers(tenantId: string): Promise<IOnboardingUser[]> {
    return await HttpService.httpGet<IOnboardingUser[]>(`${tenantId}/whitelist/users`);
  }

  public static async addOnboardingUser(tenantId: string, userId: string, onboardingUser: IOnboardingUser): Promise<void> {
    return await HttpService.httpPost<void>(`${tenantId}/whitelist/users/${userId}`, onboardingUser);
  }

  public static async removeOnboardingUser(tenantId: string, userId: string): Promise<void> {
    return await HttpService.httpDelete<void>(`${tenantId}/whitelist/users/${userId}`);
  }

}