import * as React from 'react';
import './WelcomeMessage.css';
import { inject, observer } from 'mobx-react';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { AdaptiveCard } from 'adaptivecards-react';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Store } from '../../../../stores';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import PlingService from '../../../../services/PlingService';

export interface IProps {
  store: Store;
}

export interface IState {
}

@inject('store')
@observer
export class WelcomeMessage extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private readonly defaultWelcomeMessage = "Hi, I'm Pling\n\nI will send you notifications about various activities to help keep you updated.\n\nWhenever there is a new activity you will get a notification here, sent as a chat message. On the message you can see more information about the activity itself.\n\nIf you get overwhelmed by the amount of chat messages I send, you can always mute me.";

  constructor(props: IProps) {
    super(props);
    PlingService.getWelcomeMessage(this.props.store.context.tid as string).then((welcomeMessage: string) => {
      if (welcomeMessage) {
        this.props.store.welcomeMessage = welcomeMessage;
      } else {
        this.props.store.welcomeMessage = this.defaultWelcomeMessage;
      }
    });
  }

  public render(): JSX.Element {
    let welcomeCardPayload = {
      "type": "AdaptiveCard",
      "body": [
        {
          "type": "TextBlock",
          "text": this.props.store.welcomeMessage,
          "wrap": true
        }
      ],
      "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
      "version": "1.5"
    }
    return (
      <div
        className={"IA_welcomeMessage"}
        style={{
        }}
      >
        <IAHeader
          id="WelcomeMessage"
          title={this.localizationService.strings.WelcomeMessageTitle}
          description={this.localizationService.strings.WelcomeMessageDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor={"#acd2e1"}
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
              headline: this.localizationService.strings.WelcomeMessageTitle,
              text: this.localizationService.strings.WelcomeMessageDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-account-setting.png"
            }
          ]}
        />
        <div
          className={this.props.store.darkMode ? 'IA_templateCardDarkMode' : 'IA_templateCard'}
          onClick={() => {
            this.props.store.showWelcomeMessagePanel = true;
          }}
          style={{
            backgroundColor: this.props.store.darkMode ? "#292929" : "transparent",
            border: this.props.store.darkMode ? "none" : "1px solid #eeeeee"
          }}
        >
          <AdaptiveCard
            payload={welcomeCardPayload}
            style={{
              width: this.props.store.isMobile ? "100%" : 400,
              clear: "both"
            }}
          />
          <IAButton
            buttonColor={this.props.store.highlightColor}
            textColor={"#ffffff"}
            label={this.localizationService.strings.Edit.toUpperCase()}
            darkMode={this.props.store.darkMode}
            onClick={() => { }}
            borderRadius={5}
            style={{
              float: "right",
              marginTop: 10
            }}
          />
        </div>
      </div>
    );
  }
}