declare global {
  interface Window { language: string; }
}
export class LocalizationService {

  public strings: any = {
    MenuItemSend: undefined,
    MenuItemHelp: undefined,
    SendMessageWithPlingTitle: undefined,
    SendMessageWithPlingDescription: undefined,
    InstructionTitle: undefined,
    InstructionDescription: undefined,
    SelectTemplate: undefined,
    Next: undefined,
    Send: undefined,
    Back: undefined,
    Title: undefined,
    Content: undefined,
    PeoplePicker: undefined,
    PeoplePickerPlaceholder: undefined,
    GroupPicker: undefined,
    GroupPickerPlaceholder: undefined,
    UploadImage: undefined,
    Preview: undefined,
    Priority: undefined,
    Standard: undefined,
    Healthy: undefined,
    Important: undefined,
    Critical: undefined,
    AddContent: undefined,
    PlingSend: undefined,
    PlingMightBeDelayed: undefined,
    Ok: undefined,
    Previous: undefined,
    IntroductionSlide1Title: undefined,
    IntroductionSlide1Content: undefined,
    IntroductionSlide2Title: undefined,
    IntroductionSlide2Content: undefined,
    IntroductionSlide3Title: undefined,
    IntroductionSlide3Content: undefined,
    IntroductionSlide4Title: undefined,
    IntroductionSlide4Content: undefined,
    PriorityMessage: undefined,
    ApplicationAdminMissingContent: undefined,
    ApplicationAdminMissingTitle: undefined,
    ApplicationAdminMissingPicker: undefined,
    Save: undefined,
    Sent: undefined,
    Received: undefined,
    LoadMore: undefined,
    SendNewMessage: undefined,
    MenuInbox: undefined,
    MenuSent: undefined,
    MenuNewMessage: undefined,
    Recipients: undefined,
    Created: undefined,
    TrackingHeaderText: undefined,
    FindPerson: undefined,
    NoState: undefined,
    HasBeenReceived: undefined,
    Working: undefined,
    WorkingInstalling: undefined,
    SentToUser: undefined,
    FailedToSend: undefined,
    FailedNotInstalled: undefined,
    UserUnsubscribed: undefined,
    UserNotWhitelisted: undefined,
    Templates: undefined,
    TemplatesTitle: undefined,
    TemplatesDescription: undefined,
    AddTemplate: undefined,
    CardPayload: undefined,
    CardDesignerByMicrosoft: undefined,
    Clear: undefined,
    Name: undefined,
    Type: undefined,
    Text: undefined,
    Number: undefined,
    Date: undefined,
    Image: undefined,
    Object: undefined,
    Placeholder: undefined,
    CardPayloadPlaceholder: undefined,
    SampleData: undefined,
    SampleDataPlaceHolder: undefined,
    AddProperty: undefined,
    NoMessagestoDisplay: undefined,
    PasteFrom: undefined,
    Delete: undefined,
    Edit: undefined,
    Update: undefined,
    DeleteTemplate: undefined,
    Cancel: undefined,
    DeliveringInfo: undefined,
    Delivered: undefined,
    Pending: undefined,
    Failed: undefined,
    View: undefined,
    All: undefined,
    Select: undefined,
    MenuItemSettings: undefined,
    NoContent: undefined,
    SettingsMenuWelcomeMessage: undefined,
    SettingsMenuAccounts: undefined,
    SettingsMenuContact: undefined,
    SettingsMenuUsers: undefined,
    WelcomeMessageTitle: undefined,
    WelcomeMessageDescription: undefined,
    EditWelcomeMessage: undefined,
    ContactInformationTitle: undefined,
    ContactInformationDescription: undefined,
    EditContactInformation: undefined,
    ContactInformationName: undefined,
    ContactInformationMail: undefined,
    ContactInformationCompany: undefined,
    UsersHeadline: undefined,
    UsersDescription: undefined,
    Add: undefined,
    ApplicationUsers: undefined,
    ProfileUsers: undefined,
    DisplayName: undefined,
    UserPrincipalName: undefined,
    SelectAProfileToAccess: undefined,
    SettingsMenuProfiles: undefined,
    RemoveApplicationUser: undefined,
    RemoveProfileUser: undefined,
    DeletePopup_Yes: undefined,
    DeletePopup_No: undefined,
    DeleteProfilePopupHeadline: undefined,
    ProfilesHeadline: undefined,
    ProfilesDescription: undefined,
    Icon: undefined,
    CreateProfile: undefined,
    EditProfile: undefined,
    ProfileImage: undefined,
    DefaultTemplate1Name: undefined,
    DefaultTemplate2Name: undefined,
    DefaultTemplate1Title: undefined,
    DefaultTemplate1Description: undefined,
    DefaultTemplate2Title: undefined,
    DefaultTemplate2Description: undefined,
    DefaultTemplate2ImageUrl: undefined,
    BackToPling: undefined,
    CreateDefaultTemplates: undefined,
    MissingInstallationLine1: undefined,
    MissingInstallationLine2: undefined,
    SettingsMenuOnboarding: undefined,
    OnboardingTitle: undefined,
    OnboardingDescription: undefined,
    OnboardingEnable: undefined,
    OnboardingUsers: undefined,
    RemoveOnboardingUser: undefined
  }

  public languageUrlTiny = 'https://intraactive-cdn.azureedge.net/scripts/tinymce/';
  public editorLanguage = '';
  public constructor() {
    let language: any;
    if (window.language) {
      language = window.language;
    } else {
      if (localStorage && !!localStorage.getItem("IA_language")) {
        language = localStorage.getItem("IA_language");
      }
    }
    const defaultLanguage = this.getLanguageFromNavigator();
    let localizedStringsEnglish;
    const localizedStrings = require(`../localization/${language ? language : defaultLanguage}.json`);
    for (const key in this.strings) {
      if (this.strings.hasOwnProperty(key)) {
        if (localizedStrings[key]) {
          // String is localized
          this.strings[key] = localizedStrings[key];
        } else {
          // String is not localized
          if (defaultLanguage !== 'English') {
            // Use English localization
            if (localizedStringsEnglish === undefined) {
              // Load English strings if not loaded already
              localizedStringsEnglish = require("../localization/English.json");
            }
            if (localizedStringsEnglish[key]) {
              this.strings[key] = localizedStringsEnglish[key];
            } else {
              // Should never reach here. Means string is not localized in language neither in English
              this.strings[key] = key;
            }
          } else {
            // Should never reach here. Means string is not localized in language neither in English
            this.strings[key] = key;
          }
        }
      }
    }
    this.editorLanguage = this.getEditorLanguage(language);
    this.languageUrlTiny = `${this.languageUrlTiny + this.editorLanguage}.js`;
  }
  public getLanguageFromNavigator(): 'Danish' | 'English' | 'Swedish' | 'German' | 'Portuguese' | 'Norwegian' {
    switch (navigator.language.substring(0, 2)) {
      case 'en':
        return 'English';
      case 'da':
        return 'Danish';
      case 'sv':
        return 'Swedish';
      case 'de':
        return 'German';
      case 'pt':
        return 'Portuguese';
      case 'nb':
        return 'Norwegian';
      default:
        return 'English';
    }
  }
  private getEditorLanguage(language: string) {
    switch (language) {
      case 'English':
        return 'en';
      case 'Danish':
        return 'da';
      case 'Swedish':
        return 'sv_SE';
      case 'Portuguese':
        return 'pt_BR';
      case 'Norwegian':
        return 'nb-NO';
      default:
        return 'en';
    }
  }

  public getFromString(key: string): string {
    let language;
    if (window.language) {
      language = window.language;
    } else {
      if (localStorage && !!localStorage.getItem("IA_language")) {
        language = localStorage.getItem("IA_language");
      }
    }
    const localizedStrings = require(`../localization/${language ? language : this.getLanguageFromNavigator()}.json`);
    if (localizedStrings[key]) {
      return localizedStrings[key];
    } else {
      return key;
    }
  }
}
