import * as React from 'react';
import { IAIcon } from 'intraactive-sdk-ui/dist/Icon';
import './Popup.css';

export interface IIAPopupProps {
  headline?: string;
  content: JSX.Element;
  darkMode: boolean;
  isMobile: boolean;
  style?: any;
  close: () => void;
}

export interface IAIPopupState {
  show: boolean;
}

export class Popup extends React.Component<IIAPopupProps, IAIPopupState> {

  constructor(props: IIAPopupProps) {
    super(props);
    this.state = {
      show: false
    }
  }

  public render(): JSX.Element {
    let top;
    const PopupContentBox = document.getElementById("IA_PopupContentBox");
    if (PopupContentBox) {
      top = `calc(50% - ${PopupContentBox.clientHeight / 2}px)`;
    } else {
      setTimeout(() => {
        this.forceUpdate();
        this.setState({ show: true });
      }, 100);
    }
    return (
      <div className={this.props.darkMode ? "IA_popupDarkMode" : "IA_popup"}>
        <div
          className={"IA_popupBackground"}
          onClick={() => {
            if (this.props.close) {
              this.props.close();
            }
          }}
        />
        <div
          id="IA_PopupContentBox"
          className={"IA_popupContentBox"}
          style={{
            top: top,
            opacity: this.state.show ? 1 : 0,
            width: this.props.isMobile ? "calc(100% - 40px)" : 400,
            ...this.props.style
          }}
        >
          {this.props.close !== undefined &&
            <IAIcon
              title={"Close"}
              size={24}
              color={"#777777"}
              style={{
                float: "right",
                borderRadius: 24,
                width: 24,
                height: 24,
                lineHeight: "24px",
                marginBottom: 10
              }}
              onClick={() => this.props.close()}
            />
          }
          {this.props.headline &&
            <div className='IA_headline'>{this.props.headline}</div>
          }
          {this.props.content}
        </div>
      </div>
    );
  }
}