import * as React from 'react';
import './Inbox.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores/Store';
import { IAIconDropDown, IIconDropDown } from 'intraactive-sdk-ui/dist/IconDropDown';
import { LocalizationService } from '../../../services/LocalizationService';
import PlingService from '../../../services/PlingService';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { Segment, Image } from '@fluentui/react-northstar';
import { AdaptiveCard } from 'adaptivecards-react';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { IAListView, IListElement, IListElementColumn } from 'intraactive-sdk-ui/dist/ListView';
import { IMessage } from '../../../interfaces/IMessage';

export interface IProps {
  store: Store;
}

export interface IState {
  selectedViewType: IIconDropDown;
  loading: boolean;
  skip: number;
  take: number;
}

@inject('store')
@observer
export class Inbox extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedViewType: {
        value: "list",
        icon: "ul list",
      },
      loading: false,
      skip: 0,
      take: 10
    }
  }

  public componentDidMount() {
    if (this.props.store.receivedPlings) {
      this.setState({ loading: false })
    }
  }

  private showNextPageButton(skip: number, take: number, totalCount: number): boolean {
    return (skip + take) - totalCount < 0;
  }

  private getMessages(isLoadMore: boolean) {
    if (isLoadMore || !this.props.store.receivedPlings) {
      this.setState({ loading: true }, () => {
        try {
          PlingService.getMyReceivedMessages(this.props.store.context.tid as string, this.state.skip, this.state.take).then(result => {
            let list = !this.props.store.receivedPlings ? [] : this.props.store.receivedPlings;
            list.push(...result.messages);
            this.props.store.receivedPlings = list;
            this.props.store.totalreceivedMessages = result.count;
            this.setState({ loading: false });
          })
        } catch (e) {
          console.log(e);
          this.setState({ loading: false });
        }
      })
    }
  }
  public render(): JSX.Element {
    if (!this.state.loading) {
      this.getMessages(false);
    }
    const cards: JSX.Element[] = [];
    const listElements: IListElement[] = [];
    if (this.props.store.receivedPlings) {
      this.props.store.receivedPlings.forEach((pling: IMessage) => {
        // List view
        const columns: IListElementColumn[] = [
          {
            header: this.localizationService.strings.Title.toUpperCase(),
            type: "text",
            text: pling.title,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }
        ]
        if (!this.props.store.isMobile) {
          columns.push({
            header: this.localizationService.strings.Content.toUpperCase(),
            type: "text",
            text: pling.content,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          });
          columns.push({
            header: this.localizationService.strings.Created.toUpperCase(),
            type: "date",
            date: (new Date(pling.created)).toISOString(),
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          });
        }
        listElements.push(
          {
            id: pling.id,
            columns: columns,
            buttons: [{
              action: "view",
              text: this.localizationService.strings.View.toUpperCase(),
              backgroundColor: this.props.store.highlightColor,
              color: "#ffffff",
            }],
            object: pling
          }
        );
        // Card view
        cards.push(
          <div
            className={this.props.store.darkMode ? 'IA_templateCardDarkMode' : 'IA_templateCard'}
            key={pling.id}
            style={{
              backgroundColor: this.props.store.darkMode ? "#292929" : "transparent",
              border: this.props.store.darkMode ? "none" : "1px solid #eeeeee"
            }}
          >
            <AdaptiveCard
              payload={pling.card}
              style={{
                width: this.props.store.isMobile ? "100%" : 400
              }}
            />
          </div>
        );
      });
    }
    return (
      <div>
        <div
          id={`Pling_View}`}
          className={this.props.store.darkMode ? "IA_PlingDark" : "IA_Pling"}
        >
          <IAHeader
            id={"Inbox"}
            title={this.localizationService.strings.SendMessageWithPlingTitle}
            description={this.localizationService.strings.SendMessageWithPlingDescription}
            expandButtonColor={this.props.store.highlightColor}
            navigationButtonColor={"#acd2e1"}
            navigationButtonIconColor="#ffffff"
            isMobile={this.props.store.isMobile}
            darkMode={this.props.store.darkMode}
            slides={[
              {
                backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
                headline: this.localizationService.strings.SendMessageWithPlingTitle,
                text: this.localizationService.strings.SendMessageWithPlingDescription,
                image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-inbox.png"
              }
            ]}
          />
          <IAIconDropDown
            options={[
              {
                value: "list",
                icon: "ul list",

              },
              {
                value: "card",
                icon: "Card view",
              }
            ]}
            selectedOption={this.state.selectedViewType}
            darkMode={this.props.store.darkMode}
            onChange={(selectedViewType: IIconDropDown) => {
              this.setState({ selectedViewType })
            }}
            style={{
              minWidth: 100,
              float: "right",
              marginBottom: 20,
              marginTop: -10
            }}
          />
          {this.props.store.receivedPlings && this.props.store.receivedPlings?.length > 0 &&
            <div>
              {this.state.selectedViewType.value === "card" ?
                cards
                :
                <IAListView
                  id="ListViewInbox"
                  listElements={listElements}
                  backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
                  hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                  buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                  defaultAction={"edit"}
                  darkMode={this.props.store.darkMode}
                  selectedAction={(action: string, id: string, object) => {
                    this.props.store.cardToView = object.card;
                  }}
                />}
            </div>
          }
        </div>
        {this.showNextPageButton(this.state.skip, this.state.take, this.props.store.totalreceivedMessages) && !this.state.loading &&
          <div className={"IA_paginationButtonContainer"}>
            <IAButton
              onClick={() => this.setState({ skip: this.state.skip + this.state.take, loading: true }, () => { this.getMessages(true) })}
              buttonColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              textColor="#ffffff"
              borderRadius={5}
              label={this.localizationService.strings.LoadMore.toUpperCase()}
            />
          </div>
        }
        {!this.state.loading && cards.length === 0 && listElements.length === 0 &&
          <>
            <img
              src="https://cdn.vsassets.io/ext/ms.vss-code-web/tags-view-content/Content/no-results.YsM6nM.png"
              style={{
                width: 222,
                height: 160,
                marginLeft: "calc(50% - 111px)"
              }}
            />
            <div className={this.props.store.darkMode ? "IA_noContentDarkMode" : "IA_noContent"}>{this.localizationService.strings.NoContent}</div>
          </>
        }
        {this.state.loading &&
          <IASpinner
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginLeft: "calc(50% - 20px)"
            }}
          />}
      </div>
    );
  }
}