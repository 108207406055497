import * as React from 'react';
import { inject, observer } from 'mobx-react';
import './Profiles.css';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { Store } from '../../../../stores';
import { IAListView } from 'intraactive-sdk-ui/dist/ListView';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import { IProfile } from '../../../../interfaces/IProfile';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { Popup } from '../../../popup/Popup';
import PlingService from '../../../../services/PlingService';

export interface IProps {
  display: boolean;
  store: Store;
}

export interface IState {
  profileToDelete?: string;
  loading: boolean;
}

@inject('store')
@observer
export class Profiles extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false
    }
  }

  public render(): JSX.Element {
    // Profiles
    const profiles: any[] = [];
    if (this.props.store.profiles && this.props.store.profiles.length > 0) {
      this.props.store.profiles.forEach((profile: IProfile) => {
        profiles.push({
          id: profile.id,
          columns: [
            {
              header: this.localizationService.strings.Icon.toUpperCase(),
              type: "preview",
              src: profile.logoUrl,
              fileType: "image/png"
            },
            {
              header: this.localizationService.strings.DisplayName.toUpperCase(),
              type: "text",
              text: profile.name,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            }
          ],
          buttons: [
            {
              action: "update",
              icon: "Edit",
              color: this.props.store.highlightColor
            },
            {
              action: "delete",
              icon: "Trash",
              color: this.props.store.highlightColor
            }
          ]
        });
      });
    }
    return (
      <>
        {this.state.profileToDelete &&
          <Popup
            isMobile={this.props.store.isMobile}
            headline={this.localizationService.strings.DeleteProfilePopupHeadline}
            close={() => this.setState({ profileToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  darkMode={this.props.store.darkMode}
                  onClick={() => {
                    this.setState({ loading: true });
                    PlingService.deleteProfile(this.props.store.context.tid as string, this.state.profileToDelete as string).then(() => {
                      PlingService.getMyProfilesAdmin(this.props.store.context.tid as string).then((profiles) => {
                        this.props.store.setProfiles(profiles);
                        this.setState({ profileToDelete: undefined, loading: false });
                      });
                    });
                  }}
                  borderRadius={5}
                  style={{
                    float: "left",
                    clear: "both"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.DeletePopup_No}
                  onClick={() => this.setState({ profileToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.display &&
          <>
            <IAHeader
              id="play-users-header"
              title={this.localizationService.strings.ProfilesHeadline}
              description={this.localizationService.strings.ProfilesDescription}
              expandButtonColor={this.props.store.highlightColor}
              navigationButtonColor={"#acd2e1"}
              navigationButtonIconColor="#ffffff"
              isMobile={this.props.store.isMobile}
              darkMode={this.props.store.darkMode}
              slides={[
                {
                  backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
                  headline: this.localizationService.strings.ProfilesHeadline,
                  text: this.localizationService.strings.ProfilesDescription,
                  image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-account-setting.png"
                }
              ]}
            />
            <div className="IA_profiles">
              <IAButton
                label={this.localizationService.strings.Add.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => this.props.store.profileToEdit = {}}
                borderRadius={5}
                // showSpinner={this.props.store.workingOnIt}
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: 20
                }}
              />
              {this.state.loading || profiles == undefined || profiles.length === 0 ?
                <IASpinner
                  color={this.props.store.highlightColor}
                  style={{
                    float: "left",
                    marginLeft: "calc(50% - 20px)",
                    clear: "both"
                  }}
                />
                :
                <div className="IA_profiles">
                  {profiles && profiles.length > 0 &&
                    <IAListView
                      id="ListViewOfProfiles"
                      listElements={profiles}
                      backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
                      hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                      buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                      defaultAction={"undefined"}
                      darkMode={this.props.store.darkMode}
                      selectedAction={(action: string, id: string) => {
                        switch (action) {
                          case "delete":
                            this.setState({ profileToDelete: id });
                            break;
                          case "update":
                            this.props.store.profileToEdit = this.props.store.profiles.find(x => x.id == id);
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  }
                </div>
              }
            </div>
          </>
        }
      </>
    );
  }
}