import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import { IADateAndTimePicker } from 'intraactive-sdk-ui/dist/DateAndTimePicker';
import './SendPanel.css';
import { AdaptiveCard } from 'adaptivecards-react';
import { Store } from '../../stores';
import { LocalizationService } from '../../services/LocalizationService';
import PlingService from '../../services/PlingService';
import { IMessageBody } from '../../interfaces/IMessageBody';
import { ITemplate } from '../../interfaces/ITemplate';
import { Popup } from '../popup/Popup';
import { ImagePicker } from '../imagePicker/ImagePicker';
import { DynamicObject } from '../../interfaces/DynamicObject';

export interface IProps {
  store: Store;
  profileId: any;
  id: string;
  onSave: () => void;
  onClose: () => void;
}

export interface IState {
  isLoading: boolean;
  previewNotification: boolean;
}

@inject('store')
@observer
export class SendPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      previewNotification: false
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (this.props.profileId !== props.profileId && this.props.store.sendNewPling) {
      this.getTemplates(props.profileId);
    }
  }

  private getTemplates(selectedProfileId?: string): void {
    this.props.store.templates = undefined;
    this.setState({ isLoading: true }, () => {
      PlingService.getAdaptiveCardsTemplates(this.props.store.context.tid as string, selectedProfileId ? selectedProfileId : this.props.store.selectedProfile!.id!).then(templates => {
        this.props.store.templates = templates;
        this.setState({ isLoading: false }, () => this.forceUpdate());
      });
    });
  }

  private sendPling(): void {
    this.props.store.sending = true;
    let title: string = "";
    let description: string = "";
    let additionalTemplateData: DynamicObject = {};
    this.props.store.template!.parameters!.forEach((parameter) => {
      if (parameter.name === "title") {
        title = parameter!.value!;
      } else if (parameter.name === "description") {
        description = parameter!.value!;
      }
      if (parameter.type == "object") {
        additionalTemplateData[parameter.name] = JSON.parse(parameter.value!);
      } else {
        additionalTemplateData[parameter.name] = parameter.value;
      }
    });
    const audienceUsers: string[] = []
    if (this.props.store.usersAudience && this.props.store.usersAudience.length > 0) {
      this.props.store.usersAudience.forEach((userAudience: any) => {
        audienceUsers.push(userAudience.userPrincipalName);
      });
    }
    const groupsAudience: string[] = [];
    if (this.props.store.groupsAudience && this.props.store.groupsAudience.length > 0) {
      this.props.store.groupsAudience.forEach((groupAudience: any) => {
        groupsAudience.push(groupAudience.id);
      });
    }
    const messageBody: IMessageBody = {
      title: title,
      templateId: this.props.store.template!.id!,
      content: description,
      source: "Pling Communicator",
      categories: [],
      audienceUsers: audienceUsers,
      audienceGroups: groupsAudience,
      additionalTemplateData: additionalTemplateData
    }
    PlingService.sendAdaptiveCard(this.props.store.context?.tid as string, this.props.store.selectedProfile?.id as string, messageBody).then(() => {
      this.props.store.sending = false;
      this.props.store.sendNewPling = false;
    }).catch((e) => {
      console.log(e);
      this.props.store.sending = false;
    })
  }

  public render(): JSX.Element {
    let cards: JSX.Element[] = [];
    if (!!this.props.store.templates) {
      this.props.store.templates.forEach((template: ITemplate) => {
        if (template && template.parameters) {
          cards.push(
            <div
              className='IA_templateCard'
              key={template.id}
              style={{
                backgroundColor: this.props.store.darkMode ? "#292929" : "transparent",
                border: this.props.store.darkMode ? "none" : "1px solid #eeeeee"
              }}
              onClick={() => {
                this.props.store.template = template;
                this.props.store.sendMessagePage++
              }}
            >
              <div
                className='IA_templateName'
              >
                {template.name}
              </div>
              <AdaptiveCard
                payload={this.props.store.addSampleDataToCardPayload(template.template, this.props.store.generateSampleDataFromProperties(template.parameters))}
                style={{
                  width: this.props.store.isMobile ? "100%" : 400
                }}
              />
              <IAButton
                buttonColor={this.props.store.highlightColor}
                textColor={"#ffffff"}
                label={this.localizationService.strings.Select.toUpperCase()}
                darkMode={this.props.store.darkMode}
                onClick={() => { }}
                borderRadius={5}
                style={{
                  float: "right",
                  marginTop: 10
                }}
              />
            </div>
          );
        }
      });
    }
    const notificationContent: JSX.Element[] = [];
    if (this.props.store.template && this.props.store.template.parameters && this.props.store.template.parameters.length > 0) {

      this.props.store.template.parameters.forEach((parameter: any) => {
        switch (parameter.type) {
          case "string":
            if (parameter.name === "description") {
              notificationContent.push(
                <>
                  <div
                    style={{
                      color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333',
                      clear: "both",
                      float: "left",
                      position: "relative",
                      width: "100%",
                      height: 40,
                      lineHeight: "40px",
                      marginTop: 7,
                      fontSize: 12
                    }}
                  >
                    {parameter.name.toUpperCase()} <span style={{ color: "red" }}>*</span>
                  </div>
                  <textarea
                    className={"IA_textArea"}
                    placeholder={parameter.placeholder}
                    value={parameter.value}
                    onChange={(event) => {
                      parameter.value = event.target.value;
                    }}
                    style={{
                      backgroundColor: this.props.store.darkMode ? this.props.store.darkModeInputFieldBackgroundColor : '#ffffff',
                      color: this.props.store.darkMode ? "#ffffff" : "#333333",
                      borderRadius: 5,
                      border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                      padding: 10,
                      boxSizing: "border-box",
                      height: 100,
                    }}
                    required={true}
                  />
                </>
              );
            } else {
              notificationContent.push(
                <IATextField
                  key={parameter.name}
                  label={parameter.name.toUpperCase()}
                  placeholder={parameter.placeholder}
                  text={parameter.value}
                  darkMode={this.props.store.darkMode}
                  borderRadius={5}
                  style={{ marginBottom: '10px' }}
                  labelStyle={{
                    textTransform: "uppercase",
                    fontSize: 12
                  }}
                  onChange={(text: string) => {
                    parameter.value = text;
                  }}
                  required={true}
                />
              );
            }
            break;
          case "image":
            notificationContent.push(
              <ImagePicker
                store={this.props.store}
                label={parameter.name.toUpperCase()}
                placeholder={parameter.placeholder}
                imageUrl={parameter.value}
                onChange={(imageUrl: string) => {
                  parameter.value = imageUrl;
                }}
                required={true}
              />
            );
            break;
          case "object":
            parameter.value = parameter.value ? parameter.value : parameter.placeholder;
            notificationContent.push(
              <>
                <div
                  style={{
                    color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333',
                    clear: "both",
                    float: "left",
                    position: "relative",
                    width: "100%",
                    height: 40,
                    lineHeight: "40px",
                    marginTop: 7,
                    fontSize: 12
                  }}
                >
                  {parameter.name.toUpperCase()} <span style={{ color: "red" }}>*</span>
                </div>
                <textarea
                  className={"IA_textArea"}
                  placeholder={parameter.placeholder}
                  value={parameter.value}
                  onChange={(event) => {
                    try {
                      if (JSON.parse(event.target.value)) {
                        parameter.value = event.target.value;
                      }
                    } catch (error) {
                      console.warn(error);
                    }
                  }}
                  style={{
                    backgroundColor: this.props.store.darkMode ? this.props.store.darkModeInputFieldBackgroundColor : '#ffffff',
                    color: this.props.store.darkMode ? "#ffffff" : "#333333",
                    borderRadius: 5,
                    border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                    padding: 10,
                    boxSizing: "border-box",
                    height: 100,
                    fontFamily: "monospace"
                  }}
                  required={true}
                />
              </>
            );
            break;
          case "date":
            notificationContent.push(
              <IADateAndTimePicker
                label={parameter.name.toUpperCase()}
                selectedDate={parameter.value}
                showTimeSelector={true}
                highlightColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                isClearable={false}
                onChange={(date: Date) => {
                  parameter.value = date.toJSON().replace(".000Z", "Z");
                }}
                style={{
                  width: "100%",
                  marginTop: 10,
                  float: "left"
                }}
                required={true}
              />
            );
            break;
        }
      })
    }
    let disabled = true;
    switch (this.props.store.sendMessagePage) {
      case 1:
        disabled = true;
        break;
      case 2:
        disabled = this.props.store.template?.parameters ? !this.props.store.template.parameters.every(x => x.value) : true;
        break;
      case 3:
        disabled = !(this.props.store.usersAudience?.length > 0 || this.props.store.groupsAudience?.length > 0);
        break;
      default:
        break;
    }
    return (
      <IAPanel
        open={this.props.store.sendNewPling}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={990}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix={`send-pling-panel-${this.props.id}`}
        panelId={`send-pling-panel-${this.props.id}`}
        isInTeams
        navigationsBarContent={
          <>
            <IAButton
              buttonColor={this.props.store.highlightColor}
              textColor={"#ffffff"}
              disbaled={disabled}
              label={this.props.store.sendMessagePage === 3 ? this.localizationService.strings.Send.toUpperCase() : this.localizationService.strings.Next.toUpperCase()}
              darkMode={this.props.store.darkMode}
              showSpinner={this.props.store.sending}
              borderRadius={5}
              style={{
                float: "right",
                marginTop: 7
              }}
              onClick={() => !disabled && (this.props.store.sendMessagePage === 3 ? this.sendPling() : this.props.store.sendMessagePage++)}
            />
            <IAButton
              buttonColor={"gray"}
              textColor={"#ffffff"}
              disbaled={this.props.store.sendMessagePage === 1}
              label={this.localizationService.strings.Previous.toUpperCase()}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              style={{
                float: "right",
                marginTop: 7,
                marginRight: 10
              }}
              onClick={() => this.props.store.sendMessagePage !== 1 && this.props.store.sendMessagePage--}
            />
            {this.props.store.isMobile &&
              <IAButton
                label={this.localizationService.strings.Preview.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                disbaled={this.props.store.template == undefined}
                onClick={() => {
                  this.setState({ previewNotification: true });
                }}
                borderRadius={5}
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: 7,
                  marginRight: 10
                }}
                darkMode={this.props.store.darkMode}
              />
            }
          </>
        }
        close={() => this.props.onClose()}
      >
        <div className={this.props.store.darkMode ? 'IA_sendPlingPanelDarkMode' : 'IA_sendPlingPanel'}>
          <div
            className='IA_sendPanelContent'
            style={{
              width: this.props.store.isMobile ? "100%" : undefined,
              padding: this.props.store.isMobile ? "0 20px" : "0 40px"
            }}
          >
            {this.props.store.sendMessagePage === 1 &&
              <>
                {/* Select template */}
                <h3
                  style={{
                    color: this.props.store.darkMode ? "#ffffff" : "#333333"
                  }}
                >
                  {this.localizationService.strings.SelectTemplate}
                </h3>
                {this.state.isLoading ?
                  <IASpinner
                    color={this.props.store.highlightColor}
                    style={{
                      float: "left",
                      marginLeft: "calc(50% - 20px)"
                    }}
                  />
                  :
                  <div
                    style={{
                      marginTop: 20
                    }}
                  >
                    {cards?.length > 0 ?
                      cards
                      :
                      <IAButton
                        onClick={() => {
                          this.setState({ isLoading: true }, () => {
                            this.props.store.createDefaultTemplates(this.props.profileId).then(() => {
                              this.getTemplates();
                            });
                          });
                        }}
                        buttonColor={this.props.store.highlightColor}
                        darkMode={this.props.store.darkMode}
                        textColor="#ffffff"
                        borderRadius={5}
                        label={this.localizationService.strings.CreateDefaultTemplates.toUpperCase()}
                        style={{
                          marginTop: 20
                        }}
                      />
                    }
                  </div>
                }
              </>
            }
            {this.props.store.sendMessagePage === 2 &&
              <>
                <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.AddContent}</h3>
                {notificationContent}
              </>
            }
            {this.props.store.sendMessagePage === 3 &&
              <>
                <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.Recipients}</h3>
                <>
                  <div
                    style={{
                      color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333',
                      clear: "both",
                      float: "left",
                      position: "relative",
                      width: "100%",
                      height: 40,
                      lineHeight: "40px",
                      marginTop: 7,
                      fontSize: 12
                    }}
                  >
                    {this.localizationService.strings.PeoplePicker.toUpperCase()}
                  </div>
                  <div
                    className="IA_peoplePicker"
                    style={{
                      border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                      backgroundColor: this.props.store.darkMode ? "#414141" : "transparent",
                    }}
                  >
                    <PeoplePicker
                      placeholder={this.localizationService.strings.PeoplePickerPlaceholder}
                      type={PersonType.person}
                      userType={UserType.user}
                      style={{
                        borderRadius: 5
                      }}
                      selectedPeople={this.props.store.usersAudience}
                      selectionChanged={(e: any) => {
                        this.props.store.usersAudience = e.target.selectedPeople;
                      }}
                    />
                  </div>
                  {/* Selected groups */}
                  <div
                    style={{
                      color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333',
                      clear: "both",
                      float: "left",
                      position: "relative",
                      width: "100%",
                      height: 40,
                      lineHeight: "40px",
                      marginTop: 7,
                      fontSize: 12
                    }}
                  >
                    {this.localizationService.strings.GroupPicker.toUpperCase()}
                  </div>
                  <div
                    className="IA_peoplePicker"
                    style={{
                      border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                      backgroundColor: this.props.store.darkMode ? "#414141" : "transparent",
                    }}
                  >
                    <PeoplePicker
                      placeholder={this.localizationService.strings.GroupPickerPlaceholder}
                      type={PersonType.group}
                      style={{
                        borderRadius: 5
                      }}
                      selectedPeople={this.props.store.groupsAudience}
                      selectionChanged={(e: any) => {
                        this.props.store.groupsAudience = e.target.selectedPeople;
                      }}
                    />
                  </div>
                </>
              </>
            }
          </div>
          {!this.props.store.isMobile &&
            <div
              className='IA_sendPanelPreview'
              style={{
                backgroundColor: this.props.store.darkMode ? "#414141" : "#777777"
              }}
            >
              {this.props.store.template ?
                <AdaptiveCard
                  payload={this.props.store.addSampleDataToCardPayload(this.props.store.template.template, this.props.store.generateSampleDataFromProperties(this.props.store.template.parameters!))}
                  style={{
                    width: 400
                  }}
                />
                :
                <div className='IA_sendPanelPreviewNoCard' />
              }
            </div>
          }
        </div>

        {this.state.previewNotification && this.props.store.template &&
          <Popup
            close={() => this.setState({ previewNotification: false })}
            content={
              <AdaptiveCard
                payload={this.props.store.addSampleDataToCardPayload(this.props.store.template.template, this.props.store.generateSampleDataFromProperties(this.props.store.template.parameters!))}
                style={{
                  width: this.props.store.isMobile ? "100%" : 400,
                  clear: "both"
                }}
              />
            }
            isMobile={this.props.store.isMobile}
            darkMode={this.props.store.darkMode}
            style={{
              width: this.props.store.isMobile ? "calc(100% - 40px)" : 440
            }}
          />
        }
      </IAPanel>
    );
  }
}