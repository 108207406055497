import * as React from 'react';
import { inject, observer } from 'mobx-react';
import './Users.css';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { Store } from '../../../../stores';
import { IAListView } from 'intraactive-sdk-ui/dist/ListView';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import { IProfile } from '../../../../interfaces/IProfile';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { Popup } from '../../../popup/Popup';
import PlingService from '../../../../services/PlingService';
import GraphService from '../../../../services/GraphService';
import { Group } from '../../../group/Group';

export interface IProps {
  store: Store;
  display: boolean;
  reloadKey?: string;
}

export interface IState {
  applicationUsers?: any[];
  profileUserToDelete?: string;
  applicationUserToDelete?: string;
  selectedProfileId?: string;
  loading: boolean;
  usersOnProfiles: any[];
}

@inject('store')
@observer
export class Users extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      usersOnProfiles: []
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if ((props.display && !this.props.display) || (this.props.reloadKey != props.reloadKey)) {
      this.getProfiles();
    }
  }

  private getProfiles(): void {
    this.setState({ loading: true });
    PlingService.getApplicationUsers(this.props.store.context.tid as string).then((userIds) => {
      const applicationUsers: any[] = [];
      if (userIds && userIds.length > 0) {
        userIds.forEach((userId: string) => {
          GraphService.getUser(this.props.store.context.tid as string, userId).then((user) => {
            applicationUsers.push(user);
            this.setState({ applicationUsers, loading: false });
          });
        });
      }
      const usersOnProfiles: any[] = [];
      usersOnProfiles.length = this.props.store.profiles.length;
      this.props.store.profiles.forEach((profile: IProfile, index) => {
        PlingService.getProfileUsers(this.props.store.context.tid as string, profile.id as string).then((userIds) => {
          const profileUsers: any[] = [];
          if (userIds && userIds.length > 0) {
            userIds.forEach((userId: string) => {
              GraphService.getUser(this.props.store.context.tid as string, userId).then((user) => {
                profileUsers.push(user);
                if (userIds.length === profileUsers.length) {
                  usersOnProfiles.push({
                    profile: profile,
                    profileUsers: profileUsers
                  });
                  this.setState({ usersOnProfiles, loading: false });
                }
              });
            });
          } else {
            usersOnProfiles.push({
              profile: profile,
              profileUsers: []
            });
            this.setState({ usersOnProfiles, loading: false });
          }
        });
      });
    });
  }

  private onDelete() {
    if (this.state.profileUserToDelete) {
      PlingService.deleteProfileUser(this.props.store.context.tid as string, this.state.selectedProfileId as string, this.state.profileUserToDelete).then(() => {
        this.setState({ profileUserToDelete: undefined, selectedProfileId: undefined });
        this.getProfiles();
      });
    } else if (this.state.applicationUserToDelete) {
      PlingService.deleteApplicationUser(this.props.store.context.tid as string, this.state.applicationUserToDelete).then(() => {
        this.setState({ applicationUserToDelete: undefined });
        this.getProfiles();
      });
    }
  }

  public render(): JSX.Element {
    // Application users
    const applicationUsers: any[] = [];
    if (this.props.store.isBotAdmin && this.state.applicationUsers && this.state.applicationUsers.length > 0) {
      this.state.applicationUsers.forEach((user: any) => {
        applicationUsers.push({
          id: user.id,
          columns: [
            {
              header: this.localizationService.strings.DisplayName.toUpperCase(),
              type: "text",
              text: user.displayName,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            },
            {
              header: this.localizationService.strings.UserPrincipalName.toUpperCase(),
              type: "text",
              text: user.userPrincipalName,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            }
          ],
          buttons: [
            {
              action: "delete",
              icon: "Trash",
              color: this.props.store.highlightColor
            }
          ]
        });
      });
    }
    // Profile users
    const profileList: JSX.Element[] = [];
    if (this.state.usersOnProfiles.length > 0) {
      this.state.usersOnProfiles.forEach((profile: any) => {
        const usersOnProfile: any[] = [];
        if (profile && profile.profileUsers.length > 0) {
          profile.profileUsers.forEach((profileUser: any) => {
            usersOnProfile.push({
              id: profileUser.id,
              object: profile.profile,
              columns: [
                {
                  header: this.localizationService.strings.DisplayName.toUpperCase(),
                  type: "text",
                  text: profileUser.displayName,
                  backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                  color: this.props.store.darkMode ? "#ffffff" : "#333333",
                },
                {
                  header: this.localizationService.strings.UserPrincipalName.toUpperCase(),
                  type: "text",
                  text: profileUser.userPrincipalName,
                  backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                  color: this.props.store.darkMode ? "#ffffff" : "#333333",
                }
              ],
              buttons: [
                {
                  action: "delete",
                  icon: "Trash",
                  color: this.props.store.highlightColor
                }
              ]
            });
          });
        }
        profileList.push(
          <Group
            key={profile.profile.id}
            label={profile.profile.name}
            count={profile.profileUsers.length}
            darkMode={this.props.store.darkMode}
            labelColor={this.props.store.darkMode ? this.props.store.darkModeLabelColor : "#333333"}
          >
            <IAListView
              id="ListViewOfProfilesUsers"
              listElements={usersOnProfile}
              backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              defaultAction={"undefined"}
              darkMode={this.props.store.darkMode}
              selectedAction={(action: string, id: string, profile: any) => {
                switch (action) {
                  case "delete":
                    this.setState({ profileUserToDelete: id, selectedProfileId: profile?.id });
                    break;
                  default:
                    break;
                }
              }}
            />
          </Group>
        );
      });
    }
    return (
      <>
        {(this.state.profileUserToDelete || this.state.applicationUserToDelete) &&
          <Popup
            isMobile={this.props.store.isMobile}
            headline={this.state.applicationUserToDelete ? this.localizationService.strings.RemoveApplicationUser : this.localizationService.strings.RemoveProfileUser}
            close={() => this.setState({ profileUserToDelete: undefined, applicationUserToDelete: undefined, selectedProfileId: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.onDelete()}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.DeletePopup_No}
                  onClick={() => this.setState({ profileUserToDelete: undefined, applicationUserToDelete: undefined, selectedProfileId: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.display &&
          <>
            <IAHeader
              id="play-users-header"
              title={this.localizationService.strings.UsersHeadline}
              description={this.localizationService.strings.UsersDescription}
              expandButtonColor={this.props.store.highlightColor}
              navigationButtonColor={"#acd2e1"}
              navigationButtonIconColor="#ffffff"
              isMobile={this.props.store.isMobile}
              darkMode={this.props.store.darkMode}
              slides={[
                {
                  backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
                  headline: this.localizationService.strings.UsersHeadline,
                  text: this.localizationService.strings.UsersDescription,
                  image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-account-setting.png"
                }
              ]}
            />
            {this.props.store.isBotAdmin &&
              <div className="IA_users">
                <h1
                  style={{
                    width: "calc(100% - 200px)",
                    color: this.props.store.darkMode ? "#ffffff" : "#333333"
                  }}
                >
                  {this.localizationService.strings.ApplicationUsers}
                </h1>
                <IAButton
                  label={this.localizationService.strings.Add.toUpperCase()}
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.props.store.isAddingApplicationUser = true}
                  borderRadius={5}
                  // showSpinner={this.props.store.workingOnIt}
                  style={{
                    float: "right",
                    position: "relative",
                    marginTop: 20
                  }}
                />
                {this.state.loading ?
                  <IASpinner
                    color={this.props.store.highlightColor}
                    style={{
                      float: "left",
                      marginLeft: "calc(50% - 20px)",
                      clear: "both"
                    }}
                  />
                  :
                  <div className="IA_users">
                    {applicationUsers && applicationUsers.length > 0 &&
                      <IAListView
                        id="ListViewOfAdminUsers"
                        listElements={applicationUsers}
                        backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
                        hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                        buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                        defaultAction={"undefined"}
                        darkMode={this.props.store.darkMode}
                        selectedAction={(action: string, id: string) => {
                          switch (action) {
                            case "delete":
                              this.setState({ applicationUserToDelete: id });
                              break;
                            default:
                              break;
                          }
                        }}
                      />
                    }
                  </div>
                }
              </div>
            }
            <div className="IA_users"
              style={{
                marginTop: 40
              }}
            >
              <h1
                style={{
                  width: "calc(100% - 200px)",
                  color: this.props.store.darkMode ? "#ffffff" : "#333333"
                }}
              >
                {this.localizationService.strings.ProfileUsers}
              </h1>
              <IAButton
                label={this.localizationService.strings.Add.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => this.props.store.isAddingProfileUser = true}
                borderRadius={5}
                // showSpinner={this.props.store.workingOnIt}
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: 20
                }}
              />
              <div className="IA_users">
                {this.state.loading ?
                  <IASpinner
                    color={this.props.store.highlightColor}
                    style={{
                      float: "left",
                      marginLeft: "calc(50% - 20px)",
                      clear: "both"
                    }}
                  />
                  :
                  profileList
                }
              </div>
            </div>
          </>
        }
      </>
    );
  }
}