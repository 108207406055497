import { TeamsFx } from "@microsoft/teamsfx";
import * as microsoftTeams from '@microsoft/teams-js';
import HttpService from "./HttpService";
import { IGetGraphTokenResponse } from "../interfaces/IGetGraphTokenResponse";
import { localStoragePrefix } from "../constants";
import moment from "moment";

export default class AuthenticationService {
  private static teamsfx = new TeamsFx();
  private static scopes = ["User.Read", "User.ReadBasic.All", "People.Read", "Group.Read.All"];

  /**
   * Gets Azure AD token from memory cache.
   * When it expires, it fetches a new one.
   */
  public static async getAuthToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      microsoftTeams.authentication.getAuthToken({
        silent: true,
        successCallback: (token) => { resolve(token) },
        failureCallback: (reason) => { reject("AuthenticationService > Error getting Azure AD token - reason: " + reason) }
      });
    });
  }

  /**
   * Gets Graph token from memory cache.
   * When it expires, it fetches a new one.
   */
  public static async getGraphToken(tenantId: string): Promise<string> {
    const sessionStorageKey = `${localStoragePrefix}.graphToken`;

    // Check session storage value
    const graphTokenStorageString = sessionStorage.getItem(sessionStorageKey);
    if (graphTokenStorageString) {
      const graphTokenStorage: IGetGraphTokenResponse = JSON.parse(graphTokenStorageString);
      const expirationTimeLimit = moment(new Date()).subtract(3, "minutes").toDate();
      if (new Date(graphTokenStorage.expirationTime) > expirationTimeLimit && graphTokenStorage.scopes?.includes("Group.Read.All")) {
        return graphTokenStorage.accessToken;
      }
    }

    // Fetch Graph token from our API using On-Behalf-Of flow
    try {
      const graphTokenResponse = await HttpService.httpGet<IGetGraphTokenResponse>(`${tenantId}/token/graph`);

      // Save Graph token in session storage for next time
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(graphTokenResponse));

      return graphTokenResponse.accessToken;
    } catch (error) {
      // Fallback to TeamsFX flow without Single Sign-On (in case consent is not given or MFA is required)
      return await this.getGraphTokenFromTeams();
    }
  }

  private static async getGraphTokenFromTeams(): Promise<string> {
    try {
      const accessToken = await this.teamsfx.getCredential().getToken(this.scopes);
      return accessToken!.token;
    } catch (error: any) {
      if (error.code?.includes("UiRequiredError")) {
        await this.teamsfx.login(this.scopes);
        return await this.getGraphTokenFromTeams();
      } else {
        throw error;
      }
    }
  }
}