import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../../stores';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import './EditTemplatePanel.css';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField';
import { IADropDown } from 'intraactive-sdk-ui/dist/DropDown';
import { IAIcon } from 'intraactive-sdk-ui/dist/Icon';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import PlingService from '../../../../services/PlingService';
import { Popup } from '../../../popup/Popup';
import { AdaptiveCard } from 'adaptivecards-react';

export interface IProps {
  store: Store;
  selectedProfileId: string;
  onClose: () => void;
  onCloseAndRefresh: () => void;
}

export interface IState {
  showDeleteTemplatePopup: boolean;
  previewTemplate: boolean;
}

@inject('store')
@observer
export class EditTemplatePanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      showDeleteTemplatePopup: false,
      previewTemplate: false
    }
  }

  private saveTemplate(): void {
    if (this.props.store.templateToEdit?.id) {
      PlingService.updateAdaptiveCardsTemplate(this.props.store.context.tid as string, this.props.selectedProfileId, this.props.store.templateToEdit!).then(() => {
        this.props.onCloseAndRefresh();
      });
    } else {
      PlingService.createAdaptiveCardsTemplate(this.props.store.context.tid as string, this.props.selectedProfileId, this.props.store.templateToEdit!).then(() => {
        this.props.onCloseAndRefresh();
      });
    }
  }

  private deleteTemplate(): void {
    PlingService.deleteAdaptiveCardsTemplate(this.props.store.context.tid as string, this.props.selectedProfileId, this.props.store.templateToEdit!.id as string).then(() => {
      this.props.onCloseAndRefresh();
    });
  }

  private disableSaveButton(): boolean {
    if (this.props.store.templateToEdit?.name == undefined ||
      this.props.store.templateToEdit?.name == "" ||
      this.props.store.templateToEdit?.template == undefined ||
      this.props.store.templateToEdit?.parameters == undefined
    ) {
      return true;
    }
    return false;
  }

  public render(): JSX.Element {
    const properties: JSX.Element[] = [];
    if (this.props.store.templateToEdit) {
      this.props.store.templateToEdit.parameters?.forEach((property: any, index) => {
        properties.push(
          <>
            <IATextField
              label={this.localizationService.strings.Name.toUpperCase()}
              placeholder={this.localizationService.strings.Title}
              text={property.name}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              style={{
                width: 150,
                clear: "both",
                float: 'left'
              }}
              labelStyle={{
                textTransform: "uppercase",
                fontSize: 12
              }}
              onChange={(name: string) => {
                this.props.store.templateToEdit!.parameters![index].name = name;
              }}
            />
            <IADropDown
              label={this.localizationService.strings.Type.toUpperCase()}
              selectedOption={property.type}
              borderRadius={5}
              darkMode={this.props.store.darkMode}
              options={[
                {
                  key: "string",
                  value: this.localizationService.strings.Text
                },
                {
                  key: "number",
                  value: this.localizationService.strings.Number
                },
                {
                  key: "date",
                  value: this.localizationService.strings.Date
                },
                {
                  key: "image",
                  value: this.localizationService.strings.Image
                },
                {
                  key: "object",
                  value: this.localizationService.strings.Object
                }
              ]}
              style={{
                position: "relative",
                float: "left",
                width: 100,
                marginLeft: 20,
                clear: "none"
              }}
              labelStyle={{
                textTransform: "uppercase",
                fontSize: 12
              }}
              onChange={(type: string) => {
                this.props.store.templateToEdit!.parameters![index].type = type;
              }}
            />
            <IATextField
              label={this.localizationService.strings.Placeholder.toUpperCase()}
              text={property.placeholder}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              style={{
                width: "calc(100% - 320px)",
                float: 'left',
                clear: "none",
                marginLeft: 20,
              }}
              labelStyle={{
                textTransform: "uppercase",
                fontSize: 12
              }}
              onChange={(placeholder: string) => {
                if (property.type == "object") {
                  try {
                    if (JSON.parse(placeholder)) {
                      this.props.store.templateToEdit!.parameters![index].placeholder = placeholder;
                    }
                  } catch (error) {
                    console.warn(error);
                  }
                } else {
                  this.props.store.templateToEdit!.parameters![index].placeholder = placeholder;
                }
              }}
            />
            <IAIcon
              title='Close'
              color='red'
              size={18}
              onClick={() => {
                this.props.store.templateToEdit!.parameters!.splice(index, 1);
              }}
              style={{
                float: 'left',
                clear: 'none',
                marginLeft: 10,
                marginTop: 48
              }}
            />
          </>
        );
      })
    }
    let payload;
    try {
      if (this.props.store.templateToEdit) {
        payload = this.props.store.addSampleDataToCardPayload(this.props.store.templateToEdit.template, this.props.store.generateSampleDataFromProperties(this.props.store.templateToEdit.parameters!));
      }
    } catch (error) {
      console.error(error);
    }
    return (
      <IAPanel
        open={this.props.store.templateToEdit != undefined}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile || window.innerWidth < 1280}
        loading={false}
        width={1280}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix={`add-template-panel`}
        panelId={`add-template-panel`}
        isInTeams
        navigationsBarContent={
          <>
            <IAButton
              label={this.props.store.templateToEdit?.id ? this.localizationService.strings.Update.toUpperCase() : this.localizationService.strings.Save.toUpperCase()}
              buttonColor={this.props.store.highlightColor}
              disbaled={this.disableSaveButton()}
              onClick={() => {
                this.saveTemplate();
              }}
              borderRadius={5}
              style={{
                float: "right",
                position: "relative",
                marginTop: 7,
                marginRight: 10
              }}
              darkMode={this.props.store.darkMode}
            />
            {this.props.store.templateToEdit?.id &&
              <IAButton
                label={this.localizationService.strings.Delete.toUpperCase()}
                buttonColor={"gray"}
                onClick={() => this.setState({ showDeleteTemplatePopup: true })}
                borderRadius={5}
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: 7,
                  marginRight: 10
                }}
                darkMode={this.props.store.darkMode}
              />
            }
            {(this.props.store.isMobile || window.innerWidth < 1280) &&
              <IAButton
                label={this.localizationService.strings.Preview.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                disbaled={payload == undefined}
                onClick={() => {
                  this.setState({ previewTemplate: true });
                }}
                borderRadius={5}
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: 7,
                  marginRight: 10
                }}
                darkMode={this.props.store.darkMode}
              />
            }
          </>
        }
        close={() => this.props.onClose()}
      >
        <div className='IA_editTemplatePanel'>
          <div
            className='IA_settings'
            style={{
              width: (this.props.store.isMobile || window.innerWidth < 1280) ? "100%" : 800
            }}
          >
            {this.props.store.templateToEdit != undefined &&
              <div className='IA_content'>
                <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.AddTemplate}</h3>
                {/* Title */}
                <IATextField
                  label={this.localizationService.strings.Title}
                  required
                  placeholder={this.localizationService.strings.Title}
                  text={this.props.store.templateToEdit.name as string}
                  darkMode={this.props.store.darkMode}
                  borderRadius={5}
                  labelStyle={{
                    textTransform: "uppercase",
                    fontSize: 12
                  }}
                  onChange={(title: string) => {
                    if (this.props.store.templateToEdit) {
                      this.props.store.templateToEdit.name = title;
                      this.forceUpdate();
                    }
                  }}
                />
                {/* Content */}
                <div
                  style={{
                    color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333',
                    clear: "both",
                    float: "left",
                    position: "relative",
                    width: "100%",
                    height: 40,
                    lineHeight: "40px",
                    marginTop: 10,
                    fontSize: 12
                  }}
                >
                  <span>{`${this.localizationService.strings.CardPayload.toUpperCase()}`}</span>
                  {this.props.store.templateToEdit.template ?
                    <span
                      onClick={() => {
                        if (this.props.store.templateToEdit) {
                          this.props.store.templateToEdit.template = "";
                        }
                      }}
                      style={{
                        marginLeft: 5,
                        color: this.props.store.highlightColor,
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                    >
                      {`${this.localizationService.strings.Clear.toUpperCase()}`}
                    </span>
                    :
                    <span
                      style={{
                        marginLeft: 5
                      }}
                    >
                      {`(${this.localizationService.strings.PasteFrom} `}
                      <a
                        href="https://adaptivecards.io/designer/"
                        target="_blank"
                        style={{
                          color: this.props.store.highlightColor
                        }}
                      >
                        {this.localizationService.strings.CardDesignerByMicrosoft}
                      </a>
                      {`)`}
                    </span>
                  }
                </div>
                <textarea
                  id="IA_CardPayload"
                  className={"IA_textArea"}
                  placeholder={this.localizationService.strings.CardPayloadPlaceholder}
                  value={this.props.store.templateToEdit.template && JSON.stringify(this.props.store.templateToEdit.template, undefined, 2)}
                  onChange={(event) => {
                    if (this.props.store.templateToEdit) {
                      if (event.target.value.length === 1) {
                        this.props.store.templateToEdit.template = undefined;
                      } else {
                        try {
                          this.props.store.templateToEdit.template = JSON.parse(event.target.value);
                        } catch (e) {
                          console.warn(e);
                        }
                      }
                      this.forceUpdate();
                    }
                  }}
                  style={{
                    backgroundColor: this.props.store.darkMode ? this.props.store.darkModeInputFieldBackgroundColor : '#ffffff',
                    color: this.props.store.darkMode ? "#ffffff" : "#333333",
                    borderRadius: 5,
                    border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                    padding: 10,
                    boxSizing: "border-box",
                    height: 200,
                    fontFamily: "monospace"
                  }}
                />
                <div
                  style={{
                    clear: "both",
                    float: "left",
                    position: "relative",
                    fontSize: 12,
                    marginTop: 10,
                    lineHeight: "40px",
                    height: 40,
                    width: "100%",
                    color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333'
                  }}
                >
                  <span>{`${this.localizationService.strings.SampleData.toUpperCase()}`}</span>
                  {this.props.store.templateToEdit.parameters ?
                    <span
                      onClick={() => {
                        if (this.props.store.templateToEdit) {
                          this.props.store.templateToEdit.parameters = undefined;
                        }
                      }}
                      style={{
                        marginLeft: 5,
                        color: this.props.store.highlightColor,
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                    >
                      {`${this.localizationService.strings.Clear.toUpperCase()}`}
                    </span>
                    :
                    <span
                      style={{
                        marginLeft: 5
                      }}
                    >
                      {`(${this.localizationService.strings.PasteFrom} `}
                      <a
                        href="https://adaptivecards.io/designer/"
                        target="_blank"
                        style={{
                          color: this.props.store.highlightColor
                        }}
                      >
                        {this.localizationService.strings.CardDesignerByMicrosoft}
                      </a>
                      {`)`}
                    </span>
                  }
                </div>
                <textarea
                  id="IA_SampleData"
                  className={"IA_textArea"}
                  placeholder={this.localizationService.strings.SampleDataPlaceHolder}
                  value={this.props.store.templateToEdit && this.props.store.templateToEdit.parameters ? JSON.stringify(this.props.store.copySampleData(this.props.store.templateToEdit!.parameters!), undefined, 2) : ""}
                  onChange={(event) => {
                    if (this.props.store.templateToEdit) {
                      if (event.target.value.length === 1) {
                        this.props.store.templateToEdit!.parameters = undefined;
                      } else {
                        try {
                          if (JSON.parse(event.target.value)) {
                            this.props.store.templateToEdit!.parameters = this.props.store.getProperties(event.target.value);
                          }
                        } catch (error) {
                          console.warn(error);
                        }
                      }
                      this.forceUpdate();
                    }
                  }}
                  style={{
                    backgroundColor: this.props.store.darkMode ? this.props.store.darkModeInputFieldBackgroundColor : '#ffffff',
                    color: this.props.store.darkMode ? "#ffffff" : "#333333",
                    borderRadius: 5,
                    border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                    padding: 10,
                    boxSizing: "border-box",
                    height: 200,
                    fontFamily: "monospace"
                  }}
                />
                {this.props.store.templateToEdit.parameters != undefined &&
                  <div style={{
                    position: "relative",
                    float: "left",
                    width: "100%",
                  }}>
                    {properties}
                  </div>
                }
                <div style={{
                  position: "relative",
                  float: "left",
                  width: "100%",
                  marginTop: 20,
                  paddingBottom: 20,
                }}>
                  <IAButton
                    label={this.localizationService.strings.AddProperty.toUpperCase()}
                    buttonColor={this.props.store.highlightColor}
                    onClick={() => {
                      if (this.props.store.templateToEdit && this.props.store.templateToEdit.parameters) {
                      } else {
                        this.props.store.templateToEdit!.parameters = [];
                      }
                      this.props.store.templateToEdit!.parameters.push({
                        name: "",
                        type: "string",
                        placeholder: ""
                      })
                    }}
                    borderRadius={5}
                    darkMode={this.props.store.darkMode}
                  />
                </div>
              </div>
            }
          </div>
          {!(this.props.store.isMobile || window.innerWidth < 1280) &&
            <div
              className={this.props.store.darkMode ? "IA_previewDarkMode" : "IA_preview"}
              style={{
                width: 480
              }}
            >
              {payload ?
                <div style={{
                  position: "relative",
                  float: "left",
                  width: "100%",
                  marginTop: 20,
                  paddingBottom: 20,
                }}>
                  <AdaptiveCard
                    payload={payload}
                    style={{
                      width: 400
                    }}
                  />
                </div>
                :
                <div className='IA_sendPanelPreviewNoCard' />
              }
            </div>
          }
        </div>
        {this.state.previewTemplate &&
          <Popup
            close={() => this.setState({ previewTemplate: false })}
            content={
              <AdaptiveCard
                payload={payload}
                style={{
                  width: (this.props.store.isMobile || window.innerWidth < 1280) ? "100%" : 400,
                  clear: "both"
                }}
              />
            }
            isMobile={(this.props.store.isMobile || window.innerWidth < 1280)}
            darkMode={this.props.store.darkMode}
            style={{
              width: (this.props.store.isMobile || window.innerWidth < 1280) ? "calc(100% - 40px)" : 440
            }}
          />
        }
        {this.state.showDeleteTemplatePopup &&
          <Popup
            headline={this.localizationService.strings.DeleteTemplate}
            close={() => this.setState({ showDeleteTemplatePopup: false })}
            isMobile={(this.props.store.isMobile || window.innerWidth < 1280)}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  textColor={"#ffffff"}
                  label={this.localizationService.strings.Delete.toUpperCase()}
                  darkMode={this.props.store.darkMode}
                  onClick={() => {
                    this.deleteTemplate();
                    this.setState({ showDeleteTemplatePopup: false });
                  }
                  }
                  borderRadius={5}
                  style={{
                    position: "relative",
                    float: "left",
                    marginTop: 10,
                    clear: "both"
                  }}
                />
                <IAButton
                  buttonColor={"gray"}
                  textColor={"#ffffff"}
                  label={this.localizationService.strings.Cancel.toUpperCase()}
                  darkMode={this.props.store.darkMode}
                  onClick={() => {
                    this.setState({ showDeleteTemplatePopup: false });
                  }}
                  borderRadius={5}
                  style={{
                    position: "relative",
                    float: "left",
                    marginTop: 10,
                    marginLeft: 15
                  }}
                />
              </>
            }
            darkMode={this.props.store.darkMode}
            style={{
              width: (this.props.store.isMobile || window.innerWidth < 1280) ? "calc(100% - 40px)" : 440
            }}
          />
        }
      </IAPanel>
    );
  }
}