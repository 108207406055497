import * as React from 'react';
import './Outbox.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores/Store';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAListView, IListElement } from 'intraactive-sdk-ui/dist/ListView';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import PlingService from '../../../services/PlingService';
import { Helper } from '../../../Helper';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { IATooltip } from 'intraactive-sdk-ui/dist/Tooltip';
import { TrackingDataPanel } from './trackingDataPanel/TrackingDataPanel';
import moment from 'moment';
import { IAIconDropDown, IIconDropDown } from 'intraactive-sdk-ui/dist/IconDropDown';
import { AdaptiveCard } from 'adaptivecards-react';

export interface IProps {
  store: Store;
  selectedProfileId: string;
}

export interface IState {
  isLoading: boolean;
  skip: number;
  take: number;
  selectedViewType: IIconDropDown;
}

@inject('store')
@observer
export class Outbox extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      skip: 0,
      take: 10,
      selectedViewType: {
        value: "list",
        icon: "ul list",
      },
    }
  }

  public componentDidMount(): void {
    this.props.store.trackingMessages = [];
    this.getTrackingList(this.props.selectedProfileId);
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.selectedProfileId !== this.props.selectedProfileId) {
      this.props.store.trackingMessages = [];
      this.getTrackingList(props.selectedProfileId);
    }
  }

  private getTrackingList(selectedProfileId: string) {
    this.setState({ isLoading: true }, () => {
      PlingService.getTrackingMessages(this.props.store.context.tid as string, selectedProfileId, this.state.skip, this.state.take).then(trackingMessages => {
        this.props.store.trackingMessages?.push(...trackingMessages.value);
        this.props.store.totalTrackingMessages = trackingMessages.count;
        this.setState({ isLoading: false })
      });
    });
  }

  public render(): JSX.Element {
    const listElements: IListElement[] = [];
    const cards: JSX.Element[] = [];
    if (this.props.store.trackingMessages) {
      this.props.store.trackingMessages.forEach(item => {
        const elementOverview = (<div>
          {item.overview &&
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {item.overview.successCount > 0 &&
                <IATooltip
                  content="Tooltip"
                  type={this.props.store.isMobile ? "popup" : "bubble"}
                  styles={{
                    width: 100,
                    textAlign: "center",
                    color: "#333333",
                  }}
                  componentContent={
                    <div>{this.localizationService.strings.Delivered}</div>
                  }
                >
                  <div className='IA_label'>
                    <img
                      className='IA_labelImage'
                      src={"https://intraactivestorage.blob.core.windows.net/cdn/pling/success.png"}
                      style={{
                        backgroundColor: "#92c352"
                      }}
                    />
                    <div
                      className='IA_labelText'
                      style={{
                        backgroundColor: this.props.store.darkMode ? "#1f1f1f" : "#e6e6e2",
                        marginLeft: -30,
                        paddingLeft: 35,
                        paddingRight: 20
                      }}
                    >
                      {`${item.overview.successCount}`}
                    </div>
                  </div>
                </IATooltip>
              }
              {item.overview.pendingCount > 0 &&
                <IATooltip
                  content="Tooltip"
                  type={this.props.store.isMobile ? "popup" : "bubble"}
                  styles={{
                    width: 100,
                    textAlign: "center",
                    color: "#333333",
                  }}
                  componentContent={
                    <div>{this.localizationService.strings.Pending}</div>
                  }
                >
                  <div className='IA_label'>
                    <img
                      className='IA_labelImage'
                      src={"https://intraactivestorage.blob.core.windows.net/cdn/pling/pending.png"}
                      style={{
                        backgroundColor: "gray"
                      }}
                    />
                    <div
                      className='IA_labelText'
                      style={{
                        backgroundColor: this.props.store.darkMode ? "#1f1f1f" : "#e6e6e2",
                        marginLeft: -30,
                        paddingLeft: 35,
                        paddingRight: 20
                      }}
                    >
                      {`${item.overview.pendingCount}`}
                    </div>
                  </div>
                </IATooltip>
              }
              {item.overview.failCount > 0 &&
                <IATooltip
                  content="Tooltip"
                  type={this.props.store.isMobile ? "popup" : "bubble"}
                  styles={{
                    width: 100,
                    textAlign: "center",
                    color: "#333333",
                  }}
                  componentContent={
                    <div>{this.localizationService.strings.Failed}</div>
                  }
                >
                  <div className='IA_label'>
                    <img
                      className='IA_labelImage'
                      src={"https://intraactivestorage.blob.core.windows.net/cdn/pling/failed.png"}
                      style={{
                        backgroundColor: "red"
                      }}
                    />
                    <div
                      className='IA_labelText'
                      style={{
                        backgroundColor: this.props.store.darkMode ? "#1f1f1f" : "#e6e6e2",
                        marginLeft: -30,
                        paddingLeft: 35,
                        paddingRight: 20
                      }}
                    >
                      {`${item.overview.failCount}`}
                    </div>
                  </div>
                </IATooltip>
              }
            </div>

          }
        </div>
        );
        let columns: any[] = [];
        columns = [
          {
            header: this.localizationService.strings.Title.toUpperCase(),
            type: "text",
            text: item.title ? item.title : "no title",
            color: this.props.store.darkMode ? "#ffffff" : (item.title ? "#333333" : "#afafaf"),
          },
          {
            header: this.localizationService.strings.DeliveringInfo.toUpperCase(),
            type: "customElement",
            element: elementOverview,
            color: this.props.store.darkMode ? "#ffffff" : "#333333",
          }
        ];
        if(!this.props.store.isMobile) {
          columns.push(
            {
              header: this.localizationService.strings.Created.toUpperCase(),
              type: "text",
              text: moment(item.created).format('DD-MM-YYYY HH:mm'),
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            });
        }
        listElements.push(
          {
            id: "1",
            columns: columns,
            buttons: [
              {
                action: "view",
                text: this.localizationService.strings.View.toUpperCase(),
                backgroundColor: this.props.store.highlightColor,
                color: "#ffffff",
              },
              {
                action: "overview",
                text: this.localizationService.strings.DeliveringInfo.toUpperCase(),
                backgroundColor: this.props.store.highlightColor,
                color: "#ffffff",
              }
            ],
            object: item
          }
        );
        // Card view
        cards.push(
          <div
            className={this.props.store.darkMode ? 'IA_templateCardDarkMode' : 'IA_templateCard'}
            key={item.id}
            onClick={() => {
              this.props.store.trackedMessage = item;
              this.props.store.showTrackingDetails = true;
            }}
          >
            <AdaptiveCard
              payload={item.card}
              style={{
                width: this.props.store.isMobile ? "100%" : 400
              }}
            />
            <div style={{
              float: "left"
            }}>
              {elementOverview}
            </div>
            <IAButton
              buttonColor={this.props.store.highlightColor}
              textColor={"#ffffff"}
              label={this.localizationService.strings.DeliveringInfo.toUpperCase()}
              darkMode={this.props.store.darkMode}
              onClick={() => { }}
              borderRadius={5}
              style={{
                float: "right",
                marginTop: 10
              }}
            />
          </div>
        );
      });
    }

    return (
      <div
        id={`Pling_View_tracking`}
        className={this.props.store.darkMode ? "IA_PlingDark" : "IA_Pling"}
      >
        <IAHeader
          id={"Outbox"}
          title={this.localizationService.strings.MenuSent}
          description={this.localizationService.strings.TrackingHeaderText}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor={"#acd2e1"}
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
              headline: this.localizationService.strings.MenuSent,
              text: this.localizationService.strings.TrackingHeaderText,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-tracking.png"
            }
          ]}
        />
        <IAIconDropDown
          options={[
            {
              value: "list",
              icon: "ul list",

            },
            {
              value: "card",
              icon: "Card view",
            }
          ]}
          selectedOption={this.state.selectedViewType}
          darkMode={this.props.store.darkMode}
          onChange={(selectedViewType: IIconDropDown) => {
            this.setState({ selectedViewType })
          }}
          style={{
            minWidth: 100,
            float: "right",
            marginBottom: 20,
            marginTop: -10
          }}
        />
        <div>
          {this.state.selectedViewType.value === "card" ?
            cards
            :
            <IAListView
              id="ListViewOutbox"
              listElements={listElements}
              backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              defaultAction={"overview"}
              darkMode={this.props.store.darkMode}
              selectedAction={(action: string, id: string, object) => {
                switch (action) {
                  case 'overview':
                    this.props.store.trackedMessage = object;
                    this.props.store.showTrackingDetails = true;
                    break;
                  case 'view':
                    this.props.store.cardToView = object.card;
                    break;
                  default:
                    break;
                }
              }}
            />
          }
          {Helper.showNextPageButton(this.state.skip, this.state.take, this.props.store.totalTrackingMessages) && !this.state.isLoading &&
            <div className={"IA_paginationButtonContainer"}>
              <IAButton
                onClick={() => this.setState({ skip: this.state.skip + this.state.take, isLoading: true }, () => { this.getTrackingList(this.props.selectedProfileId) })}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                textColor="#ffffff"
                borderRadius={5}
                label={this.localizationService.strings.LoadMore.toUpperCase()}
              />
            </div>
          }
          {!this.state.isLoading && cards.length === 0 && listElements.length === 0 &&
            <>
              <img
                src="https://cdn.vsassets.io/ext/ms.vss-code-web/tags-view-content/Content/no-results.YsM6nM.png"
                style={{
                  width: 222,
                  height: 160,
                  marginLeft: "calc(50% - 111px)"
                }}
              />
              <div className={this.props.store.darkMode ? "IA_noContentDarkMode" : "IA_noContent"}>{this.localizationService.strings.NoContent}</div>
            </>
          }
          {this.state.isLoading &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                marginLeft: "calc(50% - 20px)"
              }}
            />
          }
        </div>
        <TrackingDataPanel
          store={this.props.store}
          id="trackingData"
          onClose={() => {
            this.props.store.showTrackingDetails = false;
            this.props.store.trackedMessage = undefined;
          }}
        />
      </div >
    );
  }
}