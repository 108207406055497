import { Provider, teamsTheme, Loader, teamsDarkTheme } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useTeamsFx } from "@microsoft/teamsfx-react";
// import { OldTab } from "./oldComponents/OldTab";
import "./App.css";
import { TeamsFxContext } from "./Context";
import { Store } from "./stores/Store";
import { configure } from 'mobx';
import { Tab } from "./components/Tab";

export default function App() {

  const { loading, theme, themeString, teamsfx } = useTeamsFx();
  let store: Store = new Store();
  store.teamsFx = teamsfx;
  store.themeString = themeString;
  store.isMobile = window.innerWidth < 667;
  configure({
    isolateGlobalState: true,
    enforceActions: "never"
  });
  
  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsfx }}>
      <Provider theme={theme || teamsTheme || teamsDarkTheme} styles={{ backgroundColor: "#eeeeee" }}>
        <Router>
          <Route exact path="/">
            <Redirect to="/tab" />
          </Route>
            {loading ? <Loader style={{ margin: 100 }} /> : (<Route exact path="/tab" render={(props) => <Tab store={store} />} />)}
        </Router>
      </Provider>
    </TeamsFxContext.Provider >
  );
}
