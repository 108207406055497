import * as React from 'react';
import { inject, observer } from 'mobx-react';
import './OnboardingAddUserPanel.css';
import { Store } from '../../../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import PlingService from '../../../../../services/PlingService';
import { Helper } from '../../../../../Helper';
import { IOnboardingUser } from '../../../../../interfaces/IOnboardingUser';

export interface IProps {
  isAddingUser: boolean;
  store: Store;
  onClose: () => void;
}

export interface IState {
  searchTerm: string;
  isSearching: boolean;
  users: any[]
}

@inject('store')
@observer
export class OnboardingAddUserPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchTerm: '',
      isSearching: false,
      users: []
    }
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.isAddingUser}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        width={440}
        panelId={`onboarding-adding-user-panel`}
        dataAutomationIdPrefix="onboarding-adding-user-panel"
        darkMode={this.props.store.darkMode}
        close={() => {
          this.setState({ isSearching: false, searchTerm: '', users: [] });
          this.props.onClose();
        }}
      >
        {this.props.isAddingUser &&
          <div
            className="IA_onboardingAddUserPanel"
            style={{
              height: window.innerHeight - 44,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div
              className="IA_onboardingAddUserPanelContent"
              style={{
                padding: this.props.store.isMobile ? 20 : 40
              }}
            >
              <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333" }}>{this.localizationService.strings.Add}</h3>
              <div
                className="IA_peoplePicker"
                style={{
                  border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                  backgroundColor: this.props.store.darkMode ? "#414141" : "transparent",
                }}
              >
                <PeoplePicker
                  placeholder={this.localizationService.strings.PeoplePickerPlaceholder}
                  type={PersonType.person}
                  userType={UserType.user}
                  selectionMode="single"
                  style={{
                    borderRadius: 5
                  }}
                  selectionChanged={(e: any) => {
                    const user = e.target.selectedPeople[0];
                    if (this.props.store.isAddingOnboardingUser) {
                      let onboardingUser: IOnboardingUser = {
                        id: user.id,
                        userPrincipalName: user.userPrincipalName
                      };
                      PlingService.addOnboardingUser(this.props.store.context.tid as string, user.id, onboardingUser).then(() => {
                        this.props.onClose();
                        this.props.store.reloadKeyOnboarding = Helper.getRandomStringKey();
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        }
      </IAPanel>
    );
  }
}