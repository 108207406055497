import * as React from 'react';
import './Onboarding.css';
import { inject, observer } from 'mobx-react';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Store } from '../../../../stores';
import { IAToggle } from 'intraactive-sdk-ui/dist/Toggle';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import PlingService from '../../../../services/PlingService';
import { IOnboardingStatus } from '../../../../interfaces/IOnboardingStatus';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';
import { IAListView, IListElement } from 'intraactive-sdk-ui/dist/ListView';
import { IOnboardingUser } from '../../../../interfaces/IOnboardingUser';
import GraphService from '../../../../services/GraphService';
import { IGraphUser } from '../../../../interfaces/IGraphUser';
import { Popup } from '../../../popup/Popup';

export interface IProps {
  store: Store;
  reloadKey?: string;
}

export interface IState {
  loading: boolean;
  onboardingEnabled: boolean;
  onboardingGraphUsers?: IGraphUser[];
  onboardingUserToDelete?: string;
  updatingStatus: boolean;
}

@inject('store')
@observer
export class Onboarding extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      onboardingEnabled: false,
      updatingStatus: false
    }
  }

  public componentDidMount(): void {
    this.getOnboarding();
  }

  public componentWillReceiveProps(props: IProps): void {
    if (this.props.reloadKey != props.reloadKey) {
      this.getOnboarding();
    }
  }

  private getOnboarding(): void {
    this.setState({ loading: true });
    PlingService.getOnboardingStatus(this.props.store.context.tid as string).then((onboardingStatus: IOnboardingStatus) => {
      this.setState({ onboardingEnabled: onboardingStatus.enabled });
    });
    PlingService.getOnboardingUsers(this.props.store.context.tid as string).then((onboardingUsers) => {
      const onboardingGraphUsers: IGraphUser[] = [];
      if (onboardingUsers && onboardingUsers.length > 0) {
        onboardingUsers.forEach((onboardingUser: IOnboardingUser) => {
          GraphService.getUser(this.props.store.context.tid as string, onboardingUser.id).then((user) => {
            onboardingGraphUsers.push(user);
            this.setState({ onboardingGraphUsers, loading: false });
          });
        });
      } else {
        this.setState({ onboardingGraphUsers, loading: false });
      }
    });
  }

  private onDelete() {
    if (this.state.onboardingUserToDelete) {
      PlingService.removeOnboardingUser(this.props.store.context.tid as string, this.state.onboardingUserToDelete).then(() => {
        this.setState({ onboardingUserToDelete: undefined });
        this.getOnboarding();
      });
    }
  }

  public render(): JSX.Element {
    const onboardingUsersElements: IListElement[] = [];
    if (this.state.onboardingGraphUsers && this.state.onboardingGraphUsers.length > 0) {
      this.state.onboardingGraphUsers.forEach((user: IGraphUser) => {
        onboardingUsersElements.push({
          id: user.id,
          columns: [
            {
              header: this.localizationService.strings.DisplayName.toUpperCase(),
              type: "text",
              text: user.displayName,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            },
            {
              header: this.localizationService.strings.UserPrincipalName.toUpperCase(),
              type: "text",
              text: user.userPrincipalName,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            }
          ],
          buttons: [
            {
              action: "delete",
              icon: "Trash",
              color: this.props.store.highlightColor
            }
          ]
        });
      });
    }
    return (
      <div
        className={"IA_onboarding"}
      >
        {this.state.onboardingUserToDelete &&
          <Popup
            isMobile={this.props.store.isMobile}
            headline={this.localizationService.strings.RemoveOnboardingUser}
            close={() => this.setState({ onboardingUserToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.onDelete()}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.DeletePopup_No}
                  onClick={() => this.setState({ onboardingUserToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        <IAHeader
          id="Onboarding"
          title={this.localizationService.strings.OnboardingTitle}
          description={this.localizationService.strings.OnboardingDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor={"#acd2e1"}
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
              headline: this.localizationService.strings.OnboardingTitle,
              text: this.localizationService.strings.OnboardingDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-account-setting.png"
            }
          ]}
        />
        <div className="IA_onboardingView">
          <h1
            style={{
              width: "calc(100% - 200px)",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {this.localizationService.strings.OnboardingEnable}
          </h1>
          <IAToggle
            checked={this.state.onboardingEnabled}
            color={this.props.store.highlightColor}
            disabled={this.state.updatingStatus}
            onChange={async () => {
              this.setState({ updatingStatus: true });
              const newValue = !this.state.onboardingEnabled;
              const onboardingStatus: IOnboardingStatus = {
                enabled: newValue
              };
              await PlingService.updateOnboardingStatus(this.props.store.context.tid!, onboardingStatus);
              this.setState({ updatingStatus: false });
              this.getOnboarding();
            }}
          />
          {this.state.onboardingEnabled && <>
            <h1
              style={{
                width: "calc(100% - 200px)",
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }}
            >
              {this.localizationService.strings.OnboardingUsers}
            </h1>
            <IAButton
              label={this.localizationService.strings.Add.toUpperCase()}
              buttonColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onClick={() => this.props.store.isAddingOnboardingUser = true}
              borderRadius={5}
              style={{
                float: "right",
                position: "relative",
                marginTop: 20
              }}
            />
            {this.state.loading ?
              <IASpinner
                color={this.props.store.highlightColor}
                style={{
                  float: "left",
                  marginLeft: "calc(50% - 20px)",
                  clear: "both"
                }}
              />
              :
              <div className="IA_onboardingView">
                {onboardingUsersElements && onboardingUsersElements.length > 0 &&
                  <IAListView
                    id="ListViewOfOnboardingUsers"
                    listElements={onboardingUsersElements}
                    backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
                    hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                    buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                    defaultAction={"undefined"}
                    darkMode={this.props.store.darkMode}
                    selectedAction={(action: string, id: string) => {
                      switch (action) {
                        case "delete":
                          this.setState({ onboardingUserToDelete: id });
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                }
              </div>
            }
          </>
          }
        </div>
      </div>
    );
  }
}