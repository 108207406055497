import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores/Store';
import { LocalizationService } from '../services/LocalizationService';
import PlingService from '../services/PlingService';
import AuthenticationService from '../services/AuthenticationService';
import GraphService from '../services/GraphService';
import { MissingInstallation } from './MissingInstallation';
import { IAMobileMenu } from 'intraactive-sdk-ui/dist/MobileMenu';
import { IAMenu } from 'intraactive-sdk-ui/dist/Menu';
import { Popup } from './popup/Popup';
import { PeoplePicker, PersonType, Providers, ProviderState, SimpleProvider, UserType } from '@microsoft/mgt-react';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { BotAdminUserInfo, IAddBotAdminBody } from '../interfaces/IAddBotAdminBody';
import { Views } from './views/Views';
import './Tabs.css';
import { IProfile } from '../interfaces/IProfile';

export interface IProps {
  store: Store;
}

export interface IState {
  isLoading: boolean;
  users: BotAdminUserInfo[];
  loadingIsInstalled: boolean;
}

@inject('store')
@observer
export class Tab extends React.Component<IProps, IState>{

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      loadingIsInstalled: true
    }
    microsoftTeams.initialize();
  }

  public componentDidMount() {
    this.initiateCommunicatorVariables();
  }

  public componentDidUpdate(): void {
    if (this.props.store.context) {
      this.props.store.darkMode = this.props.store.context.theme === "dark";
      if (this.props.store.context.tid) {
        this.getProvider(this.props.store.context.tid!);
      }
      // if (!!!this.props.store.templates && this.props.store.selectedProfile) {
      //   this.loadTemplates();
      // }
    } else {
      this.initiateCommunicatorVariables();
    }
    window.addEventListener("resize", () => {
      if (!this.props.store.isMobile && window.innerWidth < 667) {
        this.props.store.isMobile = true;
      } else if (this.props.store.isMobile && window.innerWidth > 667) {
        this.props.store.isMobile = false;
      }
    });
  }

  private initiateCommunicatorVariables(force?: boolean) {
    if ((!this.state.isLoading && !this.props.store.context) || force) {
      this.setState({ isLoading: true });
      setTimeout(() => {
        try {
          microsoftTeams.getContext((context: microsoftTeams.Context) => {
            this.props.store.setContext(context);
            this.getProvider(context.tid!);
            PlingService.isInstalled(context.tid!).then((isInstalled) => {
              this.props.store.isInstalled = isInstalled;
              if (!this.props.store.isInstalled) {
                this.setState({ loadingIsInstalled: false });
              }
            });
            if (!this.props.store.user) {
              GraphService.getCurrentUser(context.tid!).then(user => {
                this.props.store.user = user;
                this.setState({ users: [{ id: user.id, userPrincipalName: user.userPrincipalName, displayName: user.displayName }] })
              });
            }
            PlingService.VerifyInstallationComplete(context.tid!).then((result) => {
              if ((!this.props.store.profiles || this.props.store.profiles.length === 0) && this.props.store.context?.tid) {
                PlingService.getMyProfilesAdmin(this.props.store.context.tid as string).then((profiles) => {
                  this.props.store.setProfiles(profiles);
                });
              }
            });
            PlingService.verifyHasBotAdmin(context.tid!).then(result => {
              this.props.store.hasBotAdmin = result.hasBotAdmin;
              this.setState({ isLoading: false }, () => {
                if (this.props.store.hasBotAdmin) {
                  PlingService.isBotAdmin(context.tid!).then((isBotAdmin) => {
                    this.props.store.isBotAdmin = isBotAdmin;
                  });
                }
              });
            })
          });
        } catch (e) {
          console.log(e);
        }
      }, 500);
    }
  }

  private getProvider(tenantId: string) {
    let provider = new SimpleProvider((scopes: string[]): Promise<string> => {
      return AuthenticationService.getGraphToken(tenantId).then(result => {
        const accessToken = result;
        return accessToken;
      })
        .catch((error) => {
          console.log(error);
          return "";
        });
    });
    Providers.globalProvider = provider;
    Providers.globalProvider.setState(ProviderState.SignedIn);
  }

  // private loadTemplates(): void {
  //   this.props.store.loading = true;
  //   PlingService.getAdaptiveCardsTemplates(this.props.store.context?.tid as string, (this.props.store.selectedProfile?.id as string)).then(templates => {
  //     this.props.store.setTemplates(templates);
  //     this.props.store.loading = false;
  //     this.forceUpdate();
  //   });
  // }

  public render(): JSX.Element {
    let items = [];
    items.push({
      key: "inbox",
      label: this.localizationService.strings.MenuInbox,
      icon: "Mailbox"
    });
    if (this.props.store.profiles && this.props.store.profiles.length > 0) {
      this.props.store.profiles.forEach((profile: IProfile) => {
        items.push({
          key: `newMessage_${profile.id}`,
          label: "newMessage",
          icon: "",
          customItem: (
            <div key={profile.id} style={{
              margin: 'auto',
              width: '98%',
              borderTop: "1px solid #eeeeee",
              float: "left",
              height: 50
            }}
            >
              <div
                className={"IA_profileLogo"}
                style={{
                  backgroundImage: `url("${profile.logoUrl}")`,
                  backgroundColor: "lightGray"
                }}
              />
              <div className={"IA_profileName"}>
                {profile.name}
              </div>
              <IAButton
                label={this.localizationService.strings.MenuNewMessage.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                height={30}
                style={{
                  float: "right",
                  marginRight: 10,
                  marginTop: 10
                }}
                onClick={() => {
                  this.props.store.selectedProfile = profile;
                  this.props.store.sendNewPling = true;
                }}
                borderRadius={5}
                darkMode={this.props.store.darkMode}
              />
            </div>
          )
        });
        items.push({
          key: `outbox_${profile.id}`,
          label: this.localizationService.strings.MenuSent,
          icon: "Post"
        });
        items.push({
          key: `templates_${profile.id}`,
          label: this.localizationService.strings.Templates,
          icon: "Template"
        });
      });
    }
    const bottomItems = [];
    bottomItems.push({
      key: "instruction",
      label: this.localizationService.strings.MenuItemHelp,
      icon: "User Manual"
    });
    if (this.props.store.isBotAdmin || this.props.store.profiles?.length > 0) {
      bottomItems.push({
        key: "settings",
        label: this.localizationService.strings.MenuItemSettings,
        icon: "Settings"
      });
    }
    return (
      <div
        style={{
          boxSizing: "border-box",
          width: '100%',
          height: 'auto',
          color: this.props.store.darkMode ? "#333333" : "#ffffff"
        }}
      >
        {this.props.store.isInstalled && this.props.store.isMobile &&
          <div
            style={{
              zIndex: 999999,
              position: "relative",
              background: this.props.store.darkMode ? "#1f1f1f" : "#ffffff",
              width: "100%",
              height: 50
            }}>
            <IAMobileMenu
              showMobileMenu={this.props.store.showMobileMenu}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              toogleMobileMenu={() => {
                this.props.store.showMobileMenu = !this.props.store.showMobileMenu;
              }}
            />
          </div>
        }
        {this.props.store.isInstalled &&
          <IAMenu
            darkMode={this.props.store.darkMode}
            isMobile={this.props.store.isMobile}
            showMobileMenu={this.props.store.showMobileMenu}
            highlightColor={this.props.store.highlightColor}
            items={items}
            bottomItems={bottomItems}
            selectedItem={this.props.store.selectedMenuItem}
            onSelect={(selectedMenuItem) => {
              this.props.store.showMobileMenu = false;
              if (selectedMenuItem === "settings") {
                this.props.store.showSettingsPanel = true;
              } else {
                this.props.store.selectedMenuItem = selectedMenuItem;
                if (this.props.store.profiles && this.props.store.profiles.length > 0) {
                  this.props.store.profiles.forEach((profile: IProfile) => {
                    if (selectedMenuItem.includes("_") && profile.id === selectedMenuItem.split("_")[1]) {
                      this.props.store.selectedProfile = profile;
                    }
                  });
                }
              }
            }}
            style={{
              height: this.props.store.isMobile ? "calc(100% - 50px)" : "calc(100% - 0px)",
              top: this.props.store.isMobile ? (this.props.store.showMobileMenu ? 50 : -window.screen.availHeight) : 0,
              zIndex: 1000000,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
            collapsed={false}
            showCollapseButton={false}
            isTeams
            onCollapseToggle={() => { }}
            resetProfile={() => { }}
            setProfile={() => { }}
          />
        }
        {this.props.store.isInstalled && !this.state.isLoading && !this.props.store.hasBotAdmin &&
          <Popup
            close={() => this.props.store.hasBotAdmin = true}
            isMobile={this.props.store.isMobile}
            content={
              <div style={{ textAlign: "center" }}>
                <p>{this.localizationService.strings.ApplicationAdminMissingContent}</p>
                <p
                  className='IA_label'
                  style={{
                    color: this.props.store.darkMode ? this.props.store.darkModeLabelColor : '#333'
                  }}
                >
                  {this.localizationService.strings.ApplicationAdminMissingPicker}
                </p>
                <div
                  className="IA_peoplePicker"
                  style={{
                    border: this.props.store.darkMode ? "none" : "1px solid #dddddd",
                    backgroundColor: this.props.store.darkMode ? "#414141" : "transparent",
                  }}
                >
                  <PeoplePicker
                    placeholder={this.localizationService.strings.PeoplePickerPlaceholder}
                    type={PersonType.person}
                    userType={UserType.user}
                    style={{
                      borderRadius: 5
                    }}
                    selectedPeople={this.state.users}
                    selectionChanged={(e: any) => {
                      this.setState({ users: e.target.selectedPeople });
                    }}
                  />
                </div>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  textColor={"#333333"}
                  label={this.localizationService.strings.Save}
                  darkMode={this.props.store.darkMode}
                  onClick={() => {
                    const body: IAddBotAdminBody = {
                      users: this.state.users
                    };
                    PlingService.verifyAddBotAdmin(this.props.store.context?.tid as string, body).then(() => {
                      this.initiateCommunicatorVariables(true);
                    })
                  }
                  }
                  borderRadius={5}
                  style={{
                    float: "right",
                    marginTop: 10
                  }}
                />
              </div>
            }
            darkMode={this.props.store.darkMode}
            headline={this.localizationService.strings.ApplicationAdminMissingTitle}
            style={{ "fontSize": "16px", "width": "35%" }}
          />
        }
        {!this.props.store.isInstalled && !this.state.loadingIsInstalled ?
          <MissingInstallation />
          :
          (this.props.store.isInstalled &&
            <Views
              store={this.props.store}
            />
          )
        }
      </div>
    );
  }
}