import * as React from 'react';
import './SettingsPanel.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores/Store';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { IAMobileMenu } from 'intraactive-sdk-ui/dist/MobileMenu';
import { IAMenu } from 'intraactive-sdk-ui/dist/Menu';
import { LocalizationService } from '../../../services/LocalizationService';
import { WelcomeMessage } from './welcomeMessage/WelcomeMessage';
import { ContactInformation } from './contactInformation/ContactInformation';
import { Users } from './users/Users';
import { Profiles } from './profiles/Profiles';
import { Onboarding } from './onboarding/Onboarding';

export interface IProps {
  store: Store;
}

export interface IState {
}

@inject('store')
@observer
export class SettingsPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    const isBotOrProfileAdmin = this.props.store.isBotAdmin || this.props.store.profiles?.length > 0;
    const items = [];
    if (this.props.store.isBotAdmin) {
      items.push({
        key: "welcomeMessage",
        label: this.localizationService.strings.SettingsMenuWelcomeMessage,
        icon: "Comment"
      });
      items.push({
        key: "contactInformation",
        label: this.localizationService.strings.SettingsMenuContact,
        icon: "Contact Card"
      });
      items.push({
        key: "onboarding",
        label: this.localizationService.strings.SettingsMenuOnboarding,
        icon: "Airplane"
      });
    }
    if (isBotOrProfileAdmin) {
      items.push({
        key: "users",
        label: this.localizationService.strings.SettingsMenuUsers,
        icon: "People"
      });
      items.push({
        key: "profiles",
        label: this.localizationService.strings.SettingsMenuProfiles,
        icon: "Home profile"
      });
    }
    let panelWidth = 0;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      panelWidth = rootElement.clientWidth;
    }
    return (
      <IAPanel
        open={this.props.store.showSettingsPanel}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={panelWidth}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix={`settings-panel`}
        panelId={`settings-panel`}
        isInTeams
        navigationsBarContent={<></>}
        close={() => this.props.store.showSettingsPanel = false}
      >
        {this.props.store.isMobile &&
          <div
            style={{
              zIndex: 999999,
              position: "relative",
              background: this.props.store.darkMode ? "#1f1f1f" : "#ffffff",
              width: "100%",
              height: 50
            }}>
            <IAMobileMenu
              showMobileMenu={this.props.store.showMobileSettingsMenu}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              toogleMobileMenu={() => {
                this.props.store.showMobileSettingsMenu = !this.props.store.showMobileSettingsMenu;
              }}
            />
          </div>
        }
        <IAMenu
          darkMode={this.props.store.darkMode}
          isMobile={this.props.store.isMobile}
          showMobileMenu={this.props.store.showMobileMenu}
          highlightColor={this.props.store.highlightColor}
          items={items}
          bottomItems={[{
            key: "back",
            label: this.localizationService.strings.BackToPling,
            icon: "ArrowLeft"
          }]}
          selectedItem={this.props.store.selectedSettingsMenuItem}
          onSelect={(selectedSettingsMenuItem) => {
            this.props.store.showMobileSettingsMenu = false;
            if (selectedSettingsMenuItem === "back") {
              this.props.store.showSettingsPanel = false;
            } else {
              this.props.store.selectedSettingsMenuItem = selectedSettingsMenuItem;
            }
          }}
          style={{
            height: this.props.store.isMobile ? "calc(100% - 50px)" : "calc(100% - 0px)",
            top: this.props.store.isMobile ? (this.props.store.showMobileSettingsMenu ? 0 : -window.screen.availHeight) : 0,
            zIndex: 1000000,
            float: "left",
            position: "relative"
          }}
          collapsed={false}
          showCollapseButton={false}
          isTeams
          onCollapseToggle={() => { }}
          resetProfile={() => { }}
          setProfile={() => { }}
        />
        <div
          className={"IA_settingsContentAreaWrapper"}
          style={{
            padding: this.props.store.isMobile ? 20 : 40,
            position: this.props.store.isMobile ? "absolute" : "relative",
            width: this.props.store.isMobile ? "100%" : "calc(100% - 300px)"
          }}
        >
          <Users
            store={this.props.store}
            display={this.props.store.selectedSettingsMenuItem === "users" && isBotOrProfileAdmin}
            reloadKey={this.props.store.reloadKeyUsers}
          />
          <Profiles
            store={this.props.store}
            display={this.props.store.selectedSettingsMenuItem === "profiles" && isBotOrProfileAdmin}
          />
          {this.props.store.selectedSettingsMenuItem === "welcomeMessage" && this.props.store.isBotAdmin &&
            <WelcomeMessage
              store={this.props.store}
            />
          }
          {this.props.store.selectedSettingsMenuItem === "contactInformation" && this.props.store.isBotAdmin &&
            <ContactInformation
              store={this.props.store}
            />
          }
          {this.props.store.selectedSettingsMenuItem === "onboarding" && this.props.store.isBotAdmin &&
            <Onboarding
              store={this.props.store}
              reloadKey={this.props.store.reloadKeyOnboarding}
            />
          }
        </div>
      </IAPanel>
    );
  }
}