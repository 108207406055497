import AuthenticationService from "./AuthenticationService";
import { TeamsFx } from "@microsoft/teamsfx";

export default class HttpService {
  private static endpoint = (new TeamsFx()).getConfig("apiEndpoint");

  public static async httpGet<T>(url: string): Promise<T> {
    const accessToken = await AuthenticationService.getAuthToken();
    const requestHeaders = { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` };
    const response = await fetch(`${this.endpoint}/${url}`, { method: "GET", headers: requestHeaders });
    if (!response.ok) throw new Error(" > HttpService > Error in GET request - status: " + response.status);
    const result = await response.json();
    return result;
  }

  public static async httpPost<T>(url: string, data?: any): Promise<T> {
    const accessToken = await AuthenticationService.getAuthToken();
    const requestHeaders = { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` };
    const requestBody = data ? JSON.stringify(data) : undefined;
    const response = await fetch(`${this.endpoint}/${url}`, { method: "POST", headers: requestHeaders, body: requestBody });
    if (!response.ok) throw new Error(" > HttpService > Error in POST request - status: " + response.status);
    const responseText = await response.text();
    return responseText.length ? JSON.parse(responseText) : undefined;
  }

  public static async httpPut<T>(url: string, data?: any): Promise<T> {
    const accessToken = await AuthenticationService.getAuthToken();
    const requestHeaders = { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` };
    const requestBody = data ? JSON.stringify(data) : undefined;
    const response = await fetch(`${this.endpoint}/${url}`, { method: "PUT", headers: requestHeaders, body: requestBody });
    if (!response.ok) throw new Error(" > HttpService > Error in PUT request - status: " + response.status);
    const responseText = await response.text();
    return responseText.length ? JSON.parse(responseText)  : undefined;
  }

  public static async httpDelete<T>(url: string): Promise<T> {
    const accessToken = await AuthenticationService.getAuthToken();
    const requestHeaders = { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` };
    const response = await fetch(`${this.endpoint}/${url}`, { method: "DELETE", headers: requestHeaders });
    if (!response.ok) throw new Error(" > HttpService > Error in DELETE request - status: " + response.status);
    const responseText = await response.text();
    return responseText.length ? JSON.parse(responseText)  : undefined;
  }
}