import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import './ImagePicker.css';
import { IAIcon } from 'intraactive-sdk-ui/dist/Icon'
import { Input } from '@fluentui/react-northstar';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner.module';
import { IATextField } from 'intraactive-sdk-ui/dist/TextField.module';
import PlingService from '../../services/PlingService';
import { ISaveImageRequest } from '../../interfaces/ISaveImageRequest';

export interface IImagePickerProps {
  store: Store;
  label: string;
  placeholder?: string;
  imageUrl: string;
  onChange: (imageUrl: string) => void;
  required?: boolean;
}

export interface IImagePickerState {
  savingImage: boolean;
}

@inject('store')
@observer
export class ImagePicker extends React.Component<IImagePickerProps, IImagePickerState> {

  constructor(props: IImagePickerProps) {
    super(props);
    this.state = {
      savingImage: false
    }
  }

  public render(): JSX.Element {
    return (
      <div className='IA_imagePicker'>
        <IATextField
          label={this.props.label}
          text={this.props.imageUrl}
          placeholder={this.props.placeholder}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          style={{ marginBottom: '10px', width: 'calc(100% - 25px)' }}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(imageUrl: string) => {
            this.props.onChange(imageUrl);
          }}
          required={this.props.required}
        />
        {!this.state.savingImage ?
          <>
            <IAIcon
              title='Upload'
              color={this.props.store.darkMode ? "#ffffff" : this.props.store.highlightColor}
              size={18}
              onClick={() => { }}
              style={{
                float: 'left',
                marginTop: '50px',
                marginLeft: '7px'
              }}
            />
            <Input
              inline
              fluid
              required
              type='file'
              accept='image/*'
              id='imagePicker'
              placeholder="Select image"
              style={{ opacity: 0, width: '20px', marginTop: '-28px' }}
              onChange={(event) => {
                event.persist();
                this.setState({ savingImage: true });
                let fileReader = new FileReader();
                let image = (event.target as any).files[0];
                fileReader.onload = (event: any) => {
                  const extension = image.name.split('.').pop();
                  const name = image.name.split('.')[0];
                  const body: ISaveImageRequest = {
                    image: event.target.result,
                    name: name,
                    extension: extension
                  };
                  PlingService.saveImage(this.props.store.context.tid!, this.props.store.selectedProfile!.id!, body).then((image) => {
                    this.props.onChange(image.url);
                    this.setState({ savingImage: false });
                  });
                }
                fileReader.readAsDataURL(image);
              }}
            />
          </>
          :
          <IASpinner
            color={this.props.store.highlightColor}
            style={{
              float: 'left',
              marginTop: '50px',
              marginLeft: '7px',
              marginBottom: '0px',
              width: '18px',
              height: '18px'
            }}
          />
        }
      </div>
    );
  }
}