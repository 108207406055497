import * as React from 'react';
import './Templates.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores/Store';
import { IAHeader } from 'intraactive-sdk-ui/dist/Header';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { LocalizationService } from '../../../services/LocalizationService';
import { EditTemplatePanel } from './editTemplatePanel/EditTemplatePanel';
import PlingService from '../../../services/PlingService';
import { ITemplate } from '../../../interfaces/ITemplate';
import { AdaptiveCard } from 'adaptivecards-react';
import { IAIconDropDown, IIconDropDown } from 'intraactive-sdk-ui/dist/IconDropDown';
import { IAListView, IListElement, IListElementColumn } from 'intraactive-sdk-ui/dist/ListView';
import { IASpinner } from 'intraactive-sdk-ui/dist/Spinner';

export interface IProps {
  store: Store;
  selectedProfileId: string;
}

export interface IState {
  isLoading: boolean;
  selectedViewType: IIconDropDown;
}

@inject('store')
@observer
export class Templates extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedViewType: {
        value: "list",
        icon: "ul list",
      },
      isLoading: false
    }
  }

  public componentDidMount(): void {
    this.props.store.templates = [];
    this.getTemplates(this.props.selectedProfileId);
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.selectedProfileId !== this.props.selectedProfileId || this.props.store.templates?.length === 0) {
      this.props.store.templates = [];
      this.getTemplates(props.selectedProfileId);
    }
  }

  private getTemplates(selectedProfileId?: string): void {
    this.setState({ isLoading: true }, () => {
      PlingService.getAdaptiveCardsTemplates(this.props.store.context.tid as string, selectedProfileId ? selectedProfileId : this.props.store.selectedProfile!.id!).then(templates => {
        this.props.store.templates = templates;
        this.setState({ isLoading: false });
      });
    });
  }

  public render(): JSX.Element {
    const cards: JSX.Element[] = [];
    const listElements: IListElement[] = [];
    if (this.props.store.templates && this.props.store.templates.length > 0) {
      this.props.store.templates.forEach((template: ITemplate) => {
        if (template.template && template.parameters) {
          // List view
          const columns: IListElementColumn[] = [
            {
              header: this.localizationService.strings.Title.toUpperCase(),
              type: "text",
              text: template.name,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }
          ]
          listElements.push(
            {
              id: "1",
              columns: columns,
              buttons: [
                {
                  action: "view",
                  text: this.localizationService.strings.View.toUpperCase(),
                  backgroundColor: this.props.store.highlightColor,
                  color: "#ffffff"
                }, {
                  action: "edit",
                  text: this.localizationService.strings.Edit.toUpperCase(),
                  backgroundColor: this.props.store.highlightColor,
                  color: "#ffffff",
                }
              ],
              object: template
            }
          );
          // cards
          cards.push(
            <div
              className={this.props.store.darkMode ? 'IA_templateCardDarkMode' : 'IA_templateCard'}
              key={template.id}
              onClick={() => {
                this.props.store.templateToEdit = template;
              }}
              style={{
                backgroundColor: this.props.store.darkMode ? "#292929" : "transparent",
                border: this.props.store.darkMode ? "none" : "1px solid #eeeeee"
              }}
            >
              <AdaptiveCard
                payload={this.props.store.addSampleDataToCardPayload(template.template, this.props.store.generateSampleDataFromProperties(template.parameters))}
                style={{
                  width: this.props.store.isMobile ? "100%" : 400
                }}
              />
              <IAButton
                buttonColor={this.props.store.highlightColor}
                textColor={"#ffffff"}
                label={this.localizationService.strings.Edit.toUpperCase()}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  this.props.store.templateToEdit = template;
                }}
                borderRadius={5}
                style={{
                  float: "right",
                  marginTop: 10
                }}
              />
            </div>
          );
        }
      });
    }
    return (
      <div
        className={"IA_templates"}
        style={{
        }}
      >
        <EditTemplatePanel
          store={this.props.store}
          selectedProfileId={this.props.store.selectedProfile?.id as string}
          onClose={() => this.props.store.templateToEdit = undefined}
          onCloseAndRefresh={() => {
            this.props.store.templateToEdit = undefined;
            this.getTemplates();
          }}
        />
        <IAHeader
          id="Templates"
          title={this.localizationService.strings.TemplatesTitle}
          description={this.localizationService.strings.TemplatesDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor={"#acd2e1"}
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={{
            label: this.localizationService.strings.AddTemplate.toUpperCase(),
            onClick: () => {
              this.props.store.templateToEdit = {
                icon: "Card",
                description: "Description"
              };
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.AddTemplate,
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-banner.jpg",
              headline: this.localizationService.strings.TemplatesTitle,
              text: this.localizationService.strings.TemplatesDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/pling/pling-top-template.png"
            }
          ]}
        />
        <IAIconDropDown
          options={[
            {
              value: "list",
              icon: "ul list",

            },
            {
              value: "card",
              icon: "Card view",
            }
          ]}
          selectedOption={this.state.selectedViewType}
          darkMode={this.props.store.darkMode}
          onChange={(selectedViewType: IIconDropDown) => {
            this.setState({ selectedViewType })
          }}
          style={{
            minWidth: 100,
            float: "right",
            marginBottom: 20,
            marginTop: -10
          }}
        />
        <>
          {this.state.selectedViewType.value === "card" ?
            cards
            :
            <IAListView
              id="ListViewOutbox"
              listElements={listElements}
              backgroundColor={this.props.store.darkMode ? this.props.store.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              defaultAction={"edit"}
              darkMode={this.props.store.darkMode}
              selectedAction={(action: string, id: string, object) => {
                switch (action) {
                  case 'edit':
                    this.props.store.templateToEdit = object;
                    break;
                  case 'view':
                    try {
                      this.props.store.cardToView = this.props.store.addSampleDataToCardPayload(object.template, this.props.store.generateSampleDataFromProperties(object.parameters))
                    } catch (error) {
                      console.error(error);
                    }
                    break;
                  default:
                    break;
                }
              }}
            />
          }
          {!this.state.isLoading && cards.length === 0 && listElements.length === 0 &&
            <>
              <img
                src="https://cdn.vsassets.io/ext/ms.vss-code-web/tags-view-content/Content/no-results.YsM6nM.png"
                style={{
                  width: 222,
                  height: 160,
                  marginLeft: "calc(50% - 111px)"
                }}
              />
              <div className={this.props.store.darkMode ? "IA_noContentDarkMode" : "IA_noContent"}>{this.localizationService.strings.NoContent}</div>
              <div className={"IA_paginationButtonContainer"}>
                <IAButton
                  onClick={() => {
                    this.setState({ isLoading: true }, () => {
                      this.props.store.createDefaultTemplates(this.props.selectedProfileId).then(() => {
                        this.getTemplates();
                      });
                    });
                  }}
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  textColor="#ffffff"
                  borderRadius={5}
                  label={this.localizationService.strings.CreateDefaultTemplates.toUpperCase()}
                  style={{
                    marginTop: 20
                  }}
                />
              </div>
            </>
          }
          {this.state.isLoading &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                marginLeft: "calc(50% - 20px)"
              }}
            />
          }
        </>
      </div>
    );
  }
}