import * as React from 'react';
import { Text, Segment, ErrorIcon } from '@fluentui/react-northstar'
import { LocalizationService } from '../services/LocalizationService';

export interface IProps {
}

export class MissingInstallation extends React.Component<IProps> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  public render(): JSX.Element {
    return (<div style={{ textAlign: 'center', position: 'absolute', top: '35%', left: '35%' }}>
      <Segment content={<ErrorIcon size='largest' />} />
      <Segment content={
        <Text
          size='large'
          content={
            <div>
              {this.localizationService.strings.MissingInstallationLine1}
              <br />
              {this.localizationService.strings.MissingInstallationLine2}
            </div>
          }
        />
      } />
    </div>)
  }

}