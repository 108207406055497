import './Carousel.css';
import React from 'react'
import { inject, observer } from 'mobx-react';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { Store } from '../../../../stores';
import { LocalizationService } from '../../../../services/LocalizationService';

export interface IProps {
  slides: any[]
  store: Store;
}

export interface IState {
  activeIndex: number;
}

@inject('store')
@observer
export class Carousel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  private goToSlide(index: number) {
    this.setState({
      activeIndex: index
    });
  }

  private goToPrevSlide() {
    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;
    if (index < 1) {
      index = slidesLength;
    }
    --index;
    this.setState({
      activeIndex: index
    });
  }

  private goToNextSlide() {
    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length - 1;
    if (index === slidesLength) {
      index = -1;
    }
    ++index;
    this.setState({
      activeIndex: index
    });
  }

  public render(): JSX.Element {
    return (
      <div className="carousel-container" >
        <ul className="carousel__slides">
          {this.props.slides.map((slide: any, index: number) =>
            <div>
              <li
                className={
                  index === this.state.activeIndex
                    ? "carousel__slide carousel__slide--active"
                    : "carousel__slide"
                }
              >
                <div className="carousel-slide__content">{slide.content}</div>
              </li>
            </div>
          )}
        </ul>
        <ul className="carousel__indicators" style={{ marginTop: 20 }}>
          <IAButton
            buttonColor={this.props.store.highlightColor}
            textColor={"#ffffff"}
            disbaled={this.state.activeIndex === 0}
            label={this.localizationService.strings.Previous.toUpperCase()}
            darkMode={this.props.store.darkMode}
            onClick={() => this.state.activeIndex > 0 ? this.goToPrevSlide() : {}}
            borderRadius={5}
            style={{
              float: "right",
              clear: "both",
              marginRight: 20,
              marginTop: -10
            }}
          />
          {this.props.slides.map((slide: any, index: number) =>
            <div className='CarouselIndicator'>
              <li>
                <div
                  className={
                    index === this.state.activeIndex
                      ? "carousel__indicator carousel__indicator--active"
                      : "carousel__indicator"
                  }
                  style={{
                    backgroundColor: this.props.store.darkMode ? "#ffffff" : "#333333"
                  }}
                  onClick={e => this.goToSlide(index)}
                />
              </li>
            </div>
          )}
          <IAButton
            buttonColor={this.props.store.highlightColor}
            textColor={"#ffffff"}
            disbaled={this.state.activeIndex === this.props.slides?.length - 1}
            label={this.localizationService.strings.Next.toUpperCase()}
            darkMode={this.props.store.darkMode}
            onClick={() => this.state.activeIndex < this.props.slides?.length ? this.goToNextSlide() : {}}
            borderRadius={5}
            style={{
              float: "left",
              clear: "both",
              marginLeft: 30,
              marginTop: -10
            }}
          />
        </ul>
      </div >
    );
  }

}

